import { useSearch } from '@tanstack/react-router';
import { useEffect, useRef, useState } from 'react';
import { GenericError } from '../../../common/Error/GenericError';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';

import { EmptyState } from './EmptyState';
import { OfferListItem } from './OfferListItem';
import { OfferListItemSkeleton } from './OfferListItemSkeleton';
import { useFuelOrderOffers } from './useFuelOrderOffers';

export const OfferList = ({ order }) => {
    const focusOfferId = useSearch({
        from: '/_app/fuel/order/$id',
        select: (search) => search.focusOfferId,
    });

    const openedThroughNotification = !!focusOfferId;

    const offerListItemRef = useRef(null);
    const orderOffersQuery = useFuelOrderOffers(order);

    const [openOffers, setOpenOffers] = useState(focusOfferId ? [focusOfferId] : []);

    useEffect(() => {
        if (!openedThroughNotification || !offerListItemRef.current) {
            return;
        }

        const scrollOffset = -75;
        const scrollHeight = offerListItemRef.current.getBoundingClientRect().top + window.scrollY + scrollOffset;

        window.scrollTo({ top: scrollHeight, behavior: 'smooth' });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (orderOffersQuery.isPending) {
        return (
            <Box>
                {order.receiverSupplierIds?.map((id) => {
                    return <OfferListItemSkeleton key={id} />;
                })}

                {order.spot ? <EmptyState>{translate('fuelOrder.offerList.spot')}</EmptyState> : null}
            </Box>
        );
    }

    if (orderOffersQuery.isError) {
        return <GenericError />;
    }

    if (!orderOffersQuery.data.length) {
        return <EmptyState>{translate('fuelOrder.offerList.waitingForOffers')}</EmptyState>;
    }

    const isBestPrice = (offer) => {
        const cheapestOffer = orderOffersQuery.data[0];

        if (offer.state === 'ENQUIRY') {
            return false;
        }

        if (!cheapestOffer.total?.value) {
            return false;
        }

        return offer.total?.value === cheapestOffer.total.value;
    };

    const onOfferListItemClick = (offerId) => {
        setOpenOffers((prevOpenOffers) => {
            if (prevOpenOffers.includes(offerId)) {
                return prevOpenOffers.filter((openedOfferId) => openedOfferId !== offerId);
            }

            return [...prevOpenOffers, offerId];
        });
    };

    return (
        <Box>
            {orderOffersQuery.data.map((offer) => {
                const ref = focusOfferId?.includes(offer.id) ? offerListItemRef : null;
                return (
                    <OfferListItem
                        isBestPrice={isBestPrice(offer)}
                        key={offer.id}
                        offer={offer}
                        order={order}
                        expanded={openOffers?.includes(offer.id)}
                        onClick={() => onOfferListItemClick(offer.id)}
                        ref={ref}
                    />
                );
            })}

            {order.spot && order.isOpen() ? <EmptyState>{translate('fuelOrder.offerList.spot')}</EmptyState> : null}
        </Box>
    );
};
