import { GenericError } from '../../../../common/Error/GenericError';
import { translate } from '../../../../common/helpers/translate.helper';
import { Box } from '../../../../common/ui/Box';
import { OfferModel } from '../../../../offer/model/OfferModel';
import { OrderModel, OrderState } from '../../../model/OrderModel';
import { useStateSet } from '../../common/useStateSet';
import { useLubesOrderOffers } from '../hooks/useLubesOrderOffers';

import { DashedOfferCardOutline } from './OfferCard/components/DashedOfferCardOutline';
import { OfferCardSkeleton } from './OfferCard/components/OfferCardSkeleton';
import { LubesOfferCard } from './OfferCard/LubesOfferCard';

interface OfferListProps {
    order: OrderModel;
}

export const OfferList = ({ order }: OfferListProps) => {
    const offerIdSetState = useStateSet();
    const expandedOfferIds = offerIdSetState.value;
    const offersQuery = useLubesOrderOffers(order);

    if (offersQuery.isError) {
        return <GenericError />;
    }

    const onOfferCardClick = (offerId: string) => {
        if (expandedOfferIds.has(offerId)) {
            offerIdSetState.remove(offerId);
        } else {
            offerIdSetState.add(offerId);
        }
    };

    if (offersQuery.data?.length) {
        const difference = order.receiverSupplierIds.length - offersQuery.data.length;

        const cheapestOffer = offersQuery.data[0];

        const isBestLiterPrice = (offer: OfferModel) => {
            if (!cheapestOffer.ppl?.value) {
                return false;
            }

            const isOnlyOfferWithPrice =
                offersQuery.data.filter((queryOffer) => !!queryOffer.hasValidProducts()).length === 1;

            if (isOnlyOfferWithPrice || !offer.hasValidProducts()) {
                return false;
            }

            return offer.ppl?.value === cheapestOffer.ppl.value;
        };

        return (
            <Box display="grid" rowGap={5}>
                {offersQuery.data.map((offer) => (
                    <LubesOfferCard
                        key={offer.id}
                        offer={offer}
                        order={order}
                        onClick={() => onOfferCardClick(offer.id)}
                        expanded={expandedOfferIds.has(offer.id)}
                        isBestLiterPrice={isBestLiterPrice(offer)}
                    />
                ))}
                {difference > 0
                    ? Array.from({ length: difference }).map((_, index) => <OfferCardSkeleton key={index} />)
                    : null}
                {order.isSpot() && order.isOpen() ? (
                    <DashedOfferCardOutline>{translate('order.quotes.moretocome')}</DashedOfferCardOutline>
                ) : null}
            </Box>
        );
    }

    if (order.isDraft()) {
        return <DashedOfferCardOutline>{translate('order.quotes.draft')}</DashedOfferCardOutline>;
    }

    if (order.isSpot() && (!order.receiverSupplierIds || order.receiverSupplierIds.length === 0)) {
        if (order.isOpen()) {
            return <DashedOfferCardOutline>{translate('order.quotes.moretocome')}</DashedOfferCardOutline>;
        }

        if (order.state === OrderState.CANCELED) {
            return (
                <DashedOfferCardOutline>
                    {translate('order.quotes.canceledSpotEnquiryWithoutOffers')}
                </DashedOfferCardOutline>
            );
        }
    }

    return (
        <Box display="grid" rowGap={5}>
            {order.receiverSupplierIds?.map((id) => {
                return <OfferCardSkeleton key={id} />;
            })}
        </Box>
    );
};
