import { useNavigate } from '@tanstack/react-router';

import { Button } from '../../cdl/Button/Button';
import { StatePlaceholder } from '../../cdl/StatePlaceholder/StatePlaceholder';
import { translate } from '../../common/helpers/translate.helper';
import { IconGameplan } from '../../common/icons/cdl/Gameplan';
import { Box } from '../../common/ui/Box';

export const NoBunkerPlans = () => {
    const navigate = useNavigate();

    return (
        <StatePlaceholder
            title={translate('bunkerPlan.nothingPlanned')}
            description={translate('bunkerPlan.createPlanFirst')}
            Icon={IconGameplan}
            action={
                <Button emphasis="high" onClick={() => navigate({ to: '/fuel/planning/demand' })}>
                    <Box display="flex" flexDirection="row" alignItems="center" columnGap={3}>
                        {translate('bunkerPlan.viewVessels')}
                    </Box>
                </Button>
            }
        />
    );
};
