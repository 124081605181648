import { createRouter } from '@tanstack/react-router';

import { AdvancedError } from './common/Error/AdvancedError';
import { queryClient } from './lib/queryClient';
import { routeTree } from './routeTree.gen';

export const router = createRouter({
    routeTree,
    basepath: '/app/',
    context: {
        queryClient,
    },
    defaultPreload: 'intent',
    defaultPreloadDelay: 100,
    defaultPreloadStaleTime: 0,
    defaultErrorComponent: AdvancedError,
});
