import Skeleton from 'react-loading-skeleton';

import { ProductContext } from '../../../../types/ProductContext';
import { GenericError } from '../../../common/Error/GenericError';
import { translate } from '../../../common/helpers/translate.helper';
import { Section } from '../../../common/Section/Section';
import { SettingsSection } from '../../SettingsSection';

import { CustomerNotificationSettings } from './CustomerNotificationSettings';
import { LubesApprovalSettings } from './LubesApprovalSettings';
import { useCustomerSettings } from './useCustomerSettings';

interface LubesCustomerSettingsProps {
    customerId: string;
}

export const LubesCustomerSettings = (props: LubesCustomerSettingsProps) => {
    const customerSettingsQuery = useCustomerSettings(props.customerId);

    if (customerSettingsQuery.isPending) {
        return (
            <Section title={translate('customers.details.settings')}>
                <SettingsSection label={<Skeleton width="30%" height="16px" />}>
                    <Skeleton width="80%" height="16px" />
                    <Skeleton width="80%" height="16px" />
                    <Skeleton width="80%" height="16px" />
                    <Skeleton width="80%" height="16px" />
                </SettingsSection>

                <SettingsSection label={<Skeleton width="120px" height="16px" />}>
                    <Skeleton width="80%" height="16px" />
                    <Skeleton width="80%" height="16px" />
                </SettingsSection>
            </Section>
        );
    }

    if (customerSettingsQuery.isError) {
        return (
            <Section title={translate('customers.details.settings')}>
                <GenericError variant="basic" />
            </Section>
        );
    }

    const customerSettings = customerSettingsQuery.data;

    return (
        <Section title={translate('customers.details.settings')}>
            <CustomerNotificationSettings
                type={ProductContext.LUBES}
                customerSettings={customerSettings}
                customerId={props.customerId}
            />
            <LubesApprovalSettings customerSettings={customerSettings} customerId={props.customerId} />
        </Section>
    );
};
