import { useSearch } from '@tanstack/react-router';

import { AdvancedError } from '../../common/Error/AdvancedError';
import { useProductContext } from '../../common/hooks/useProductContext';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { useAdminStatistics } from '../hooks/useAdminStatistics';

import { AdminStatisticsContent } from './AdminStatisticsContent';
import { AdminStatisticsTabSwitch } from './AdminStatisticsTabSwitch';
import { NoStatistics } from './NoStatistics';
import { StatisticsCenteredFrame } from './StatisticsCenteredFrame';

export const AdminStatistics = () => {
    const { context, isFuel } = useProductContext();

    const search = useSearch({ from: isFuel ? '/_app/fuel/statistics' : '/_app/_lubes/statistics' });

    const adminStatisticsQuery = useAdminStatistics({
        from: search.from,
        to: search.to,
        customerIds: search.customerIds,
        supplierIds: search.supplierIds,
        type: context,
    });

    if (adminStatisticsQuery.isPending) {
        return (
            <div>
                <AdminStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <LoadingIndicator />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    if (adminStatisticsQuery.isError) {
        return (
            <StatisticsCenteredFrame>
                <AdvancedError />
            </StatisticsCenteredFrame>
        );
    }

    if (adminStatisticsQuery.data?.general.completedCount === 0) {
        return (
            <div>
                <AdminStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <NoStatistics />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    return (
        <div>
            <AdminStatisticsTabSwitch />
            <AdminStatisticsContent statistics={adminStatisticsQuery.data} />
        </div>
    );
};
