import { components } from 'react-select';
import styled from 'styled-components';
import { get } from '../../ui/get';

const { Option } = components;

const StyledOption = styled(Option)`
    && {
        color: ${(props) => (props.isSelected ? 'black' : 'black')};
        background-color: ${(props) =>
            props.isSelected || props.isFocused ? get('colors.background.inset') : 'white'};
        border-bottom: none;
        font-size: 14px;
        cursor: pointer;
        font-weight: 600;

        &:hover {
            background-color: ${get('colors.background.inset')};
        }
    }
`;

const StyledOptionDiv = styled.div`
    display: flex;
    align: center;
    justify: center;
    align-items: center;
`;

export const CustomOption = (props) => {
    return (
        <StyledOption {...props}>
            <StyledOptionDiv>
                <div>{props.data.label}</div>
            </StyledOptionDiv>
        </StyledOption>
    );
};
