import { ApprovalRequestMessage } from '../../../../types/ApprovalRequestMessage';
import { ApprovalRequestState } from '../../../../types/ApprovalRequestState';

import { ApprovedApprovalRequestContent } from './ApprovedApprovalRequestContent';
import { DeclinedApprovalRequestContent } from './DeclinedApprovalRequestContent';
import { ExpiredApprovalRequestContent } from './ExpiredApprovalRequestContent';
import { PendingApprovalRequestContent } from './PendingApprovalRequestContent';
import { WithdrawnApprovalRequestContent } from './WithdrawnApprovalRequestContent';

interface ApprovalRequestContentProps {
    approvalRequest: ApprovalRequestMessage;
}

export const ApprovalRequestContent = ({ approvalRequest }: ApprovalRequestContentProps) => {
    switch (approvalRequest.state) {
        case ApprovalRequestState.APPROVED:
            return <ApprovedApprovalRequestContent approvalRequest={approvalRequest} />;
        case ApprovalRequestState.DECLINED:
            return <DeclinedApprovalRequestContent approvalRequest={approvalRequest} />;
        case ApprovalRequestState.WITHDRAWN:
            return <WithdrawnApprovalRequestContent approvalRequest={approvalRequest} />;
        case ApprovalRequestState.EXPIRED:
            return <ExpiredApprovalRequestContent approvalRequest={approvalRequest} />;
        case ApprovalRequestState.PENDING:
            return <PendingApprovalRequestContent approvalRequest={approvalRequest} />;
        default:
            return null;
    }
};
