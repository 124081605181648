import { createFileRoute, redirect } from '@tanstack/react-router';

import { queries } from '../common/api/queryKeys/queries';
import { AdvancedError } from '../common/Error/AdvancedError';
import { LoadingIndicator } from '../common/LoadingIndicator/LoadingIndicator';
import { LubesEnquiryDraftPage } from '../order/lubes/create-enquiry/LubesEnquiryDraftPage';
import { OrderModel, OrderState } from '../order/model/OrderModel';

export const Route = createFileRoute('/_app/_lubes/orders_/draft/$id')({
    loader: async ({ context, params }) => {
        const order: OrderModel = await context.queryClient.ensureQueryData(queries.orders.detail(params.id));

        if (order.state !== OrderState.DRAFT) {
            throw redirect({ to: '/order/$id', params: { id: order.id } });
        }
    },
    component: LubesEnquiryDraftPage,
    errorComponent: ({ error, reset }) => <AdvancedError error={error} resetError={reset} />,
    pendingComponent: () => <LoadingIndicator variant="page" />,
});
