import { InlineCell } from '../../../../cdl/TableBuilder/components/InlineCell';
import { StyledInlineHeaderCell } from '../../../../cdl/TableBuilder/components/StyledInlineHeaderCell';
import { StyledInlineTableBodyRow } from '../../../../cdl/TableBuilder/components/StyledInlineTableBodyRow';
import { StyledTable } from '../../../../cdl/TableBuilder/components/StyledTable';
import { formatNumber } from '../../../../common/helpers/formatNumber.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useEnums } from '../../../../common/hooks/useEnums';
import { useLubesProductStringFormat } from '../../../../common/hooks/useLubesProductStringFormat';
import { getCellEmphasis } from '../../../../common/LubesReadOnlyProductTable/components/getCellEmphasis';
import { ItemModel } from '../../../../common/models/ItemModel';
import { SamplekitModel } from '../../../../common/models/SamplekitModel';

interface EnquiredProductsTableProps {
    items: ItemModel[];
    sampleKits: SamplekitModel[];
}

export const EnquiredProductsTable = ({ items, sampleKits }: EnquiredProductsTableProps) => {
    const { getHumanReadableValue } = useEnums();

    const formatProductString = useLubesProductStringFormat();

    const columns = [
        { header: translate('order.pos'), numeric: true },
        { header: translate('order.product') },
        { header: translate('order.productgroup') },
        { header: translate('order.packtype') },
        { header: translate('order.unitsize'), numeric: true },
        { header: translate('order.units'), numeric: true },
        { header: translate('order.volume'), numeric: true },
        { header: translate('order.unit') },
    ];

    return (
        <StyledTable variant="inline">
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <StyledInlineHeaderCell key={index} textAlign={column.numeric ? 'right' : 'left'}>
                            {column.header}
                        </StyledInlineHeaderCell>
                    ))}
                </tr>
            </thead>
            <tbody>
                {items.map((item, index) => (
                    <StyledInlineTableBodyRow disabled={item.deleted} key={index}>
                        <InlineCell numeric>{index + 1}</InlineCell>
                        <InlineCell>{formatProductString(item.product)}</InlineCell>
                        <InlineCell>{getHumanReadableValue('productGroup', item.product!.group)}</InlineCell>
                        <InlineCell emphasis={getCellEmphasis(item.changes.packTypeChanged)}>
                            {getHumanReadableValue('packType', item.packType)}
                        </InlineCell>
                        <InlineCell emphasis={getCellEmphasis(item.changes.unitSizeChanged)} numeric>
                            {formatNumber({ number: item.unitSize, maximumFractionDigits: 0 })}
                        </InlineCell>
                        <InlineCell emphasis={getCellEmphasis(item.changes.unitsChanged)} numeric>
                            {item.units ? formatNumber({ number: item.units, maximumFractionDigits: 0 }) : '-'}
                        </InlineCell>
                        <InlineCell numeric>
                            {item.volume ? formatNumber({ number: item.volume, maximumFractionDigits: 3 }) : '-'}
                        </InlineCell>
                        <InlineCell emphasis={getCellEmphasis(item.changes.unitChanged)}>
                            {getHumanReadableValue('productUnit', item.unit)}
                        </InlineCell>
                    </StyledInlineTableBodyRow>
                ))}

                {sampleKits.length
                    ? sampleKits.map((sampleKit, index) => (
                          <StyledInlineTableBodyRow key={index}>
                              <InlineCell numeric>{items.length + index + 1}</InlineCell>
                              <InlineCell emphasis={getCellEmphasis(sampleKit.changes.nameChanged)}>
                                  {sampleKit.name || '-'}
                              </InlineCell>
                              <InlineCell>{translate('order.samplekit.title')}</InlineCell>
                              <InlineCell colSpan={2}>{translate('order.samplekit.packtype')}</InlineCell>
                              <InlineCell emphasis={getCellEmphasis(sampleKit.changes.quantityChanged)} numeric>
                                  {sampleKit.quantity || '-'}
                              </InlineCell>
                              <InlineCell colSpan={2}></InlineCell>
                          </StyledInlineTableBodyRow>
                      ))
                    : null}
            </tbody>
        </StyledTable>
    );
};
