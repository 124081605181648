import { StatePlaceholder } from '../../cdl/StatePlaceholder/StatePlaceholder';
import { translate } from '../../common/helpers/translate.helper';
import { IconChartPie } from '../../common/icons/cdl/ChartPie';

export const NoStatistics = () => {
    return (
        <StatePlaceholder
            title={translate('reporting.noStatistics.title')}
            description={translate('reporting.noStatistics.description')}
            Icon={IconChartPie}
        />
    );
};
