import { ApprovalRequestState } from '../../../../../types/ApprovalRequestState';
import { OfferState } from '../../../../offer/model/OfferState';
import { OrderTab, OrderTabEnum } from '../../orderTabsHelper';

import { QuickFilterValue, QuickFilterValueEnum } from './QuickFilterValueTypes';

export const mapQuickFilterToTab = (tab: OrderTab) => {
    switch (tab) {
        case OrderTabEnum.ENQUIRED:
            return [
                QuickFilterValueEnum.ALL,
                QuickFilterValueEnum.ENQUIRY_OPEN,
                QuickFilterValueEnum.ENQUIRY_NOT_APPROVED,
            ];
        case OrderTabEnum.ORDERED:
            return [
                QuickFilterValueEnum.ALL,
                QuickFilterValueEnum.ORDER_NOT_CONFIRMED,
                QuickFilterValueEnum.ORDER_NOT_ACKNOWLEDGED,
                QuickFilterValueEnum.ORDER_NOT_APPROVED,
            ];
        case OrderTabEnum.DELIVERED:
            return [QuickFilterValueEnum.ALL, QuickFilterValueEnum.ORDER_NOT_APPROVED];
    }
};

export const mapFilterToApprovalState = (value: QuickFilterValue): ApprovalRequestState[] | null => {
    switch (value) {
        case QuickFilterValueEnum.ALL:
        case QuickFilterValueEnum.ENQUIRY_OPEN:
        case QuickFilterValueEnum.ORDER_NOT_ACKNOWLEDGED:
        case QuickFilterValueEnum.ORDER_NOT_CONFIRMED:
            return null;
        case QuickFilterValueEnum.ENQUIRY_NOT_APPROVED:
        case QuickFilterValueEnum.ORDER_NOT_APPROVED:
            return [
                ApprovalRequestState.DECLINED,
                ApprovalRequestState.EXPIRED,
                ApprovalRequestState.WITHDRAWN,
                ApprovalRequestState.PENDING,
            ];
    }
};

export const mapFilterToOfferState = (value: QuickFilterValue): OfferState[] | null => {
    switch (value) {
        case QuickFilterValueEnum.ALL:
        case QuickFilterValueEnum.ENQUIRY_NOT_APPROVED:
        case QuickFilterValueEnum.ORDER_NOT_APPROVED:
            return null;
        case QuickFilterValueEnum.ENQUIRY_OPEN:
            return [OfferState.ENQUIRY];
        case QuickFilterValueEnum.ORDER_NOT_ACKNOWLEDGED:
            return [OfferState.ORDER, OfferState.QUOTED, OfferState.CUSTOMER_ADJUSTED, OfferState.SUPPLIER_ADJUSTED];
        case QuickFilterValueEnum.ORDER_NOT_CONFIRMED:
            return [
                OfferState.ORDER,
                OfferState.QUOTED,
                OfferState.CUSTOMER_ADJUSTED,
                OfferState.SUPPLIER_ADJUSTED,
                OfferState.ACKNOWLEDGED,
            ];
    }
};
