import { AxiosResponse } from 'axios';

import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const downloadVesselRequisitionForVessel = (vesselId: string): Promise<AxiosResponse<Blob>> => {
    return authorizedAxiosInstance.get(`/v1/vessel-requisitions?vesselIds=${vesselId}`, {
        responseType: 'blob',
    });
};

export const downloadVesselRequisitionForCustomer = (customerId: string): Promise<AxiosResponse<Blob>> => {
    return authorizedAxiosInstance.get(`/v1/vessel-requisitions?customerIds=${customerId}`, {
        responseType: 'blob',
    });
};

export const sendVesselRequisitionToVessel = (vesselId: string): Promise<AxiosResponse> => {
    return authorizedAxiosInstance.post(`/v1/vessel-requisitions/email?vesselIds=${vesselId}`);
};
