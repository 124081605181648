import { useFormikContext } from 'formik';
import moment from 'moment';
import * as uuid from 'uuid';

import { ScheduleMessage } from '../../../../../types/ScheduleMessage';
import { formatDateTime } from '../../../../common/helpers/formatDateTime.helper';
import { CompareOverlayFlow } from '../../../compare/overlay/CompareOverlayFlow';
import { FormikCreateEnquiryValues } from '../LubesCreateEnquiryFlow';

interface Changed {
    receiverSupplierIds: string[];
    portId: string;
    dateDelivery?: {
        date: string;
        timeZoneId: string;
    };
}

interface FormikCompareOverlayProps {
    schedule: ScheduleMessage[];
}

const trackingId = uuid.v4();

export const FormikCompareOverlay = (props: FormikCompareOverlayProps) => {
    const { values, setFieldValue, setFieldTouched } = useFormikContext<FormikCreateEnquiryValues>();

    return (
        <CompareOverlayFlow
            trackingId={trackingId}
            order={values}
            schedules={props.schedule.map((mappedSchedule) => ({
                ...mappedSchedule,
                etaWithZone: {
                    date: mappedSchedule.eta,
                    timeZone: mappedSchedule.port?.timeZoneId,
                },
            }))}
            onSubmit={(changed: Changed) => {
                setFieldValue('receiverSupplierIds', changed.receiverSupplierIds);
                setFieldTouched('receiverSupplierIds', true);

                setFieldValue('portId', changed.portId);
                setFieldTouched('portId', true);

                if (changed.dateDelivery) {
                    const dateDeliveryFromSchedule = moment
                        .utc(
                            formatDateTime({
                                date: changed.dateDelivery.date,
                                timeZoneId: changed.dateDelivery.timeZoneId,
                            })
                        )
                        .startOf('day')
                        .toISOString();

                    setFieldValue('dateDelivery', dateDeliveryFromSchedule);
                    setFieldTouched('dateDelivery', true);
                }
            }}
        />
    );
};
