import styled from 'styled-components';

import { StepMarker } from './StepMarker/StepMarker';
import { get } from '../ui/get';

const Dot = styled.div`
    margin-right: ${(props) => (props.spaced ? '10px' : '0px')};
    height: 4px;
    width: 4px;
    border-radius: 100%;
    background-color: ${(props) => props.color};
`;

const DotWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
`;

const DOT_GROUP_STATE = {
    COMPLETED: 'completed',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
};

const DotGroup = (props) => {
    const getColors = (state) => {
        switch (state) {
            case DOT_GROUP_STATE.COMPLETED: {
                return ['colors.accent.foreground', 'colors.accent.foreground', 'colors.accent.foreground'];
            }

            case DOT_GROUP_STATE.ACTIVE: {
                return ['colors.accent.emphasis', 'colors.accent.border', 'colors.accent.background'];
            }

            case DOT_GROUP_STATE.INACTIVE: {
                return ['colors.foreground.disabled', 'colors.foreground.disabled', 'colors.foreground.disabled'];
            }
        }
    };

    const colors = getColors(props.state);

    return (
        <DotWrapper>
            <Dot color={get(colors[0])} spaced />
            <Dot color={get(colors[1])} spaced />
            <Dot color={get(colors[2])} />
        </DotWrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ThreeStepsProgressBar = (props) => {
    const isCircleSelected = (circleNumber, circlePosition) => circleNumber >= circlePosition;
    const getDotGroupState = (circleNumber, circlePosition) => {
        if (circleNumber > circlePosition) {
            return DOT_GROUP_STATE.COMPLETED;
        }

        if (circleNumber === circlePosition) {
            return DOT_GROUP_STATE.ACTIVE;
        }

        return DOT_GROUP_STATE.INACTIVE;
    };

    return (
        <Wrapper>
            <StepMarker active={isCircleSelected(props.circleNumber, 1)} step={1} />

            <DotGroup state={getDotGroupState(props.circleNumber, 1)} />

            <StepMarker active={isCircleSelected(props.circleNumber, 2)} step={2} />

            <DotGroup state={getDotGroupState(props.circleNumber, 2)} />

            <StepMarker active={isCircleSelected(props.circleNumber, 3)} step={3} />
        </Wrapper>
    );
};
