import { StatePlaceholder } from '../../../cdl/StatePlaceholder/StatePlaceholder';
import { translate } from '../../helpers/translate.helper';
import { IconAlertTriangle } from '../../icons/cdl/AlertTriangle';
import { Box } from '../../ui/Box';

export const CommandErrorState = () => {
    return (
        <Box display="flex" justifyContent="center">
            <StatePlaceholder
                title={translate('shortcutMenu.error.title')}
                description={translate('shortcutMenu.error.description')}
                Icon={IconAlertTriangle}
            />
        </Box>
    );
};
