import { useNavigate, useSearch } from '@tanstack/react-router';
import { ReactNode } from 'react';

import { Box } from '../../../common/ui/Box';
import { ClearFilters } from '../ClearFilters/ClearFilters';

export interface Filter<FieldType, Entry> {
    filterFn: (values: FieldType[], entry: Entry) => boolean;
    component: (values: FieldType[], setValues: (values: FieldType[]) => void) => ReactNode;
}

interface DynamicFilterContainerProps<Entry> {
    allEntries: Entry[];
    filters: Record<string, Filter<any, Entry>>;
    children: (filteredEntries: Entry[]) => ReactNode;
}

export function DynamicFilterContainer<T>(props: DynamicFilterContainerProps<T>) {
    const values = (useSearch({ strict: false })?.filter as Record<string, any[]>) ?? {};
    const navigate = useNavigate();
    const setValues = (newValues: Record<string, any[]>) => {
        navigate({
            // @ts-ignore
            search: (prevSearch) => ({
                ...prevSearch,
                filter: newValues,
            }),
        });
    };

    const filteredEntries: T[] = props.allEntries.filter((entry) => {
        return Object.entries(props.filters).every(([key, filter]) => {
            if (values[key] === undefined || values[key].length === 0) {
                return true;
            }

            return filter.filterFn(values[key] ?? [], entry);
        });
    });

    return (
        <div>
            <Box marginBottom={5} display="flex" justifyContent="space-between">
                <Box display="flex" gap={4}>
                    {Object.entries(props.filters).map(([key, filter]) => {
                        return (
                            <span key={key}>
                                {filter.component(values[key] ?? [], (updatedValues) => {
                                    setValues({
                                        ...values,
                                        [key]: updatedValues,
                                    });
                                })}
                            </span>
                        );
                    })}
                </Box>
                {Object.values(values).some((it) => it.length > 0) ? (
                    <ClearFilters onClearClick={() => setValues({})} />
                ) : null}
            </Box>

            {props.children(filteredEntries)}
        </div>
    );
}
