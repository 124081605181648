import { Button } from '../../cdl/Button/Button';
import { StatePlaceholder } from '../../cdl/StatePlaceholder/StatePlaceholder';
import { translate } from '../../common/helpers/translate.helper';
import { useOpenFreshchat } from '../../common/hooks/useOpenFreshchat';
import { IconArrowNarrowRight } from '../../common/icons/cdl/ArrowNarrowRight';
import { IconWorldOff } from '../../common/icons/cdl/WorldOff';
import { Box } from '../../common/ui/Box';

export const NoGlobalPrices = () => {
    const openFreshchat = useOpenFreshchat();

    return (
        <StatePlaceholder
            title={translate('globalPrices.noCredentials.title')}
            description={translate('globalPrices.noCredentials.description')}
            Icon={IconWorldOff}
            action={
                <Button emphasis="low" onClick={openFreshchat}>
                    <Box display="flex" flexDirection="row" alignItems="center" columnGap={3}>
                        {translate('globalPrices.noCredentials.getInTouch')}
                        <IconArrowNarrowRight />
                    </Box>
                </Button>
            }
        />
    );
};
