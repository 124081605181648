import { ApprovalRequestMessage } from '../../../../types/ApprovalRequestMessage';
import { ApprovalRequestState } from '../../../../types/ApprovalRequestState';
import { Tag } from '../../../cdl/Tag/Tag';
import { Tooltip } from '../../../cdl/Tooltip/Tooltip';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { useEnums } from '../../../common/hooks/useEnums';

const getTagVariant = (
    approvalRequestState: ApprovalRequestState
): 'positive' | 'default' | 'attention' | 'negative' | 'accent' => {
    switch (approvalRequestState) {
        case ApprovalRequestState.APPROVED:
            return 'positive';
        case ApprovalRequestState.PENDING:
            return 'default';
        case ApprovalRequestState.WITHDRAWN:
            return 'attention';
        case ApprovalRequestState.DECLINED:
        case ApprovalRequestState.EXPIRED:
            return 'negative';
    }
};

const getDate = (approvalRequest: ApprovalRequestMessage): string => {
    switch (approvalRequest.state) {
        case ApprovalRequestState.PENDING:
            return approvalRequest.dateCreated;
        case ApprovalRequestState.APPROVED:
            return approvalRequest.dateApproved;
        case ApprovalRequestState.WITHDRAWN:
            return approvalRequest.dateWithdrawn;
        case ApprovalRequestState.DECLINED:
            return approvalRequest.dateDeclined;
        case ApprovalRequestState.EXPIRED:
            return approvalRequest.dateExpired;
    }
};

interface ApprovalRequestStateTagProps {
    approvalRequest: ApprovalRequestMessage;
    showTooltip?: boolean;
}

export const ApprovalRequestStateTag = (props: ApprovalRequestStateTagProps) => {
    const { getHumanReadableValue } = useEnums();
    const date = getDate(props.approvalRequest);
    const showTooltip = props.showTooltip ?? true;

    if (!date || !showTooltip) {
        return (
            <Tag variant={getTagVariant(props.approvalRequest.state)}>
                {getHumanReadableValue('approvalRequestState', props.approvalRequest.state)}
            </Tag>
        );
    }

    return (
        <Tooltip label={formatDateTime({ date })} showArrow>
            <div style={{ display: 'inline-block' }}>
                <Tag variant={getTagVariant(props.approvalRequest.state)}>
                    {getHumanReadableValue('approvalRequestState', props.approvalRequest.state)}
                </Tag>
            </div>
        </Tooltip>
    );
};
