import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

import { getRole } from '../common/hooks/useRole';

export const Route = createFileRoute('/_app/_lubes')({
    beforeLoad: () => {
        const role = getRole();

        if (!role.mayUseContext('LUBES')) {
            throw redirect({
                to: '/fuel/dashboard',
            });
        }

        role.updateContext('LUBES');
    },
    component: () => <Outlet />,
});
