import { ProductMessage } from '../../../types/ProductMessage';

import { useEnums } from './useEnums';

export const useLubesProductStringFormat = () => {
    const { getHumanReadableValue } = useEnums();

    return (product: ProductMessage) => {
        const humanReadableValue = getHumanReadableValue('supplierProductGroup', product.supplierProductGroup);

        if (!humanReadableValue) {
            return product.name;
        }

        return `${product.name} (${humanReadableValue})`;
    };
};
