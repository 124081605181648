export const QuickFilterValueEnum = {
    ALL: 'ALL',
    ENQUIRY_NOT_APPROVED: 'ENQUIRY_NOT_APPROVED',
    ENQUIRY_OPEN: 'ENQUIRY_OPEN',
    ORDER_NOT_APPROVED: 'ORDER_NOT_APPROVED',
    ORDER_NOT_CONFIRMED: 'ORDER_NOT_CONFIRMED',
    ORDER_NOT_ACKNOWLEDGED: 'ORDER_NOT_ACKNOWLEDGED',
} as const;

export type QuickFilterValue = (typeof QuickFilterValueEnum)[keyof typeof QuickFilterValueEnum];

export type PartialQuickFilterTotals = Partial<{ [key in QuickFilterValue]: number }>;
