import { useQuery } from '@tanstack/react-query';

import { getOrdersTotal } from '../../common/api/clients/gateway.api';
import { queries } from '../../common/api/queryKeys/queries';

import {
    mapFilterToApprovalState,
    mapFilterToOfferState,
    mapQuickFilterToTab,
} from './lubes/quickfilter/quickFilterMappingHelper';
import { PartialQuickFilterTotals, QuickFilterValue } from './lubes/quickfilter/QuickFilterValueTypes';
import { orderTabOfferStateFilter, orderTabStateFilter } from './orderTabsHelper';

export const useOrderQuickFilterTotals = ({ searchQuery, customerIds, supplierIds, types, tab }) => {
    const fetchTotals = async ({ searchQuery, customerIds, supplierIds, types, tab }) => {
        const quickFilterValues = mapQuickFilterToTab(tab);

        if (!quickFilterValues) {
            return Promise.resolve(undefined);
        }
        const values = await Promise.all(
            quickFilterValues.map((quickFilter: QuickFilterValue) => {
                return getOrdersTotal({
                    searchQuery,
                    customerIds,
                    supplierIds,
                    types,
                    offerStates:
                        mapFilterToOfferState(quickFilter) ??
                        orderTabOfferStateFilter[tab as keyof typeof orderTabOfferStateFilter],
                    approvalRequestStates: mapFilterToApprovalState(quickFilter) || undefined,
                    states: orderTabStateFilter[tab as keyof typeof orderTabStateFilter],
                });
            })
        );

        const ordersTotals = values.map((value) => value.count);

        if (ordersTotals.length === 0) {
            return Promise.resolve(undefined);
        }

        const initialTotals: PartialQuickFilterTotals = {};

        return Promise.resolve(
            quickFilterValues.reduce((acc, value, index) => {
                acc[value] = ordersTotals[index];
                return acc;
            }, initialTotals)
        );
    };

    return useQuery({
        queryKey: queries.orders.quickFilterTotals({
            searchQuery,
            customerIds,
            supplierIds,
            types,
            tab,
        }).queryKey,

        queryFn: () =>
            fetchTotals({
                searchQuery,
                customerIds,
                supplierIds,
                types,
                tab,
            }),
        refetchOnWindowFocus: false,
    });
};
