import styled from 'styled-components';
import { ConditionalRenderer } from '../ConditionalRenderer/ConditionalRenderer';
import { IconInfoCircle } from '../icons/cdl/InfoCircle';
import { variant } from 'styled-system';

const Wrapper = styled.div(
    {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        padding: '18px 24px 18px 0',
        borderRadius: '4px',
    },
    variant({
        prop: 'type',
        variants: {
            danger: {
                border: '1px solid',
                borderColor: 'negative.border',
                backgroundColor: 'negative.background',
            },
            white: {
                backgroundColor: 'white',
            },
        },
    })
);

const IconWrapper = styled.div`
    position: absolute;
    height: 24px;

    margin: auto;
    top: 0;
    left: 24px;
    bottom: 0;
`;

const RightElementWrapper = styled.div`
    position: relative;
    top: 50%;
    transform: translateY(-50%);

    div {
        float: right;
    }
`;

const HeadlinesWrapper = styled.div`
    margin-left: 40px;
    padding-left: 24px;
`;

const Headline = styled.h3`
    font-size: 14px;
    font-weight: bold;

    line-height: 1.5;
    margin: 0;
`;

const SubHeadline = styled.h4`
    font-size: 14px;
    font-weight: 400;

    line-height: 1.5;
    margin: 0;
`;

/**
 * @deprecated use CDL StatusBar instead
 */

export const StatusBar = (props) => {
    const getClassNameForLeftArea = (rightElement) => {
        let className = 'p-a-0';
        if (rightElement) {
            className += ' col-xs-8';
        }

        return className;
    };

    const icon = props.icon ?? <IconInfoCircle />;
    const className = props.className ?? '';

    return (
        <Wrapper className={className} type={props.type}>
            <div className="col-xs-12 p-a-0">
                <div className={getClassNameForLeftArea(props.rightElement)}>
                    <IconWrapper>{icon}</IconWrapper>

                    <HeadlinesWrapper>
                        <Headline>{props.headline}</Headline>

                        <SubHeadline>{props.subHeadline}</SubHeadline>
                    </HeadlinesWrapper>
                </div>

                <ConditionalRenderer condition={!!props.rightElement}>
                    <RightElementWrapper className="col-xs-4 p-a-0">
                        <div>{props.rightElement}</div>
                    </RightElementWrapper>
                </ConditionalRenderer>
            </div>
        </Wrapper>
    );
};
