import { createQueryKeys } from '@lukemorales/query-key-factory';

import { ProductContext } from '../../../../types/ProductContext';
import { OrderTab } from '../../../order/OrderOverview/orderTabsHelper';
import { getOrder, getOrders, GetOrdersParams } from '../clients/gateway.api';

interface QuickFilterTotalsParams {
    searchQuery?: string;
    customerIds?: string[];
    supplierIds?: string[];
    types: ProductContext[];
    tab: OrderTab;
}

export const orders = createQueryKeys('orders', {
    detail: (id: string) => ({
        queryKey: [id],
        queryFn: () => getOrder(id),
    }),
    pagination: (params: GetOrdersParams) => ({
        queryKey: [{ params }],
        queryFn: () => getOrders(params),
    }),
    quickFilterTotals: (params: QuickFilterTotalsParams) => ({
        queryKey: [{ params }],
    }),
});
