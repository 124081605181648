import { Text } from '../../../cdl/Text/Text';
import { FuelShowOfferProductTable } from '../../../common/FuelShowOfferProductTable/FuelShowOfferProductTable';
import { HeaderWithLine } from '../../../common/HeaderWithLine/HeaderWithLine';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { Section } from '../../../common/Section/Section';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';
import { useCustomerSettings } from '../../../company/customer/detail/useCustomerSettings';
import { useOfferApprovalSettings } from '../../MultipleApprovers/hooks/useOfferApprovalSettings';
import { OfferApprovalActivity } from '../../OfferApprovalActivity';
import { OfferChat } from '../../OfferChat/OfferChat';
import { OfferDatasheet } from '../../OfferCoreData/OfferDatasheet';
import { InvolvedParties } from '../common/components/InvolvedParties';
import { OfferDetailAttachments } from '../common/components/OfferDetailAttachments';
import { OfferDetailLayoutGrid } from '../common/components/OfferDetailLayoutGrid';
import { useOfferDetails } from '../common/hooks/useOfferDetails';
import { OfferPositions } from '../OfferPositions/OfferPositions';

import { FuelOfferDetailActionBar } from './FuelOfferDetailActionBar';
import { FuelOfferDetailStatusBar } from './FuelOfferDetailStatusBar';
import { useParams } from '@tanstack/react-router';
import { AdvancedError } from '../../../common/Error/AdvancedError';

const selectTitle = (offer, titleDefinition) => {
    if (offer.isOrder()) {
        return titleDefinition.order;
    }

    if (offer.wasQuoted()) {
        return titleDefinition.offer;
    }

    return titleDefinition.enquiry;
};

const Title = ({ offer }) => {
    return selectTitle(offer, {
        order: translate('offer.detail.header.headlineOrder'),
        offer: translate('offer.detail.header.headlineOffer'),
        enquiry: translate('offer.detail.header.headlineEnquiry'),
    });
};

const getDocumentTitle = (offer) => {
    return selectTitle(offer, {
        order: translate('page.offerShowOrder', {
            portName: offer.port.name,
            vesselName: offer.vessel.name,
        }),
        offer: translate('page.offerShowOffer', {
            portName: offer.port.name,
            vesselName: offer.vessel.name,
        }),
        enquiry: translate('page.offerShowEnquiry', {
            portName: offer.port.name,
            vesselName: offer.vessel.name,
        }),
    });
};

export const FuelOfferDetailPage = () => {
    const { id } = useParams({ from: '/_app/fuel/offer/$id' });
    const role = useRole();
    const offerDetailsQuery = useOfferDetails(id);
    const offerApprovalSettingsQuery = useOfferApprovalSettings(id, role.isCustomer());
    const offer = offerDetailsQuery.data;

    const customerSettingsQuery = useCustomerSettings(offer?.customerId, !!offer?.customerId && !!role.isCustomer());
    const documentTitle = offer ? getDocumentTitle(offer) : null;

    useDocumentTitle(documentTitle);

    if (offerDetailsQuery.isPending || offerApprovalSettingsQuery.isLoading || customerSettingsQuery.isLoading) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (offerDetailsQuery.isError) {
        return (
            <Page>
                <AdvancedError />
            </Page>
        );
    }

    return (
        <Page>
            <PageGrid>
                <HeaderWithLine>
                    <Text variant="headline" as="h1">
                        <Title offer={offer} />
                        &nbsp;
                        <Text color="dark-blue.3">{offer.vessel.name}</Text>
                    </Text>

                    <Flex flexDirection="row">
                        {role.isCustomer() || role.isAdmin() ? (
                            <Box marginRight={8}>
                                <OfferApprovalActivity
                                    offer={offer}
                                    offerApprovalSettings={offerApprovalSettingsQuery.data}
                                />
                            </Box>
                        ) : null}

                        <FuelOfferDetailActionBar offer={offer} />
                    </Flex>
                </HeaderWithLine>

                <Box display="flex" flexDirection="column" rowGap={9}>
                    <FuelOfferDetailStatusBar offer={offer} customerSettings={customerSettingsQuery.data} />

                    <InvolvedParties offer={offer} />

                    <OfferDetailLayoutGrid>
                        <Box display="flex" flexDirection="column" gap={9}>
                            <Section title={translate('offer.detail.details')}>
                                <OfferDatasheet offer={offer} />
                            </Section>

                            <Section title={translate('offer.detail.products')} fullHeight={false}>
                                <FuelShowOfferProductTable
                                    showPrices={
                                        !['ENQUIRY', 'ENQUIRY_CANCELED', 'ENQUIRY_DECLINED'].includes(offer.state)
                                    }
                                    offer={offer}
                                />
                            </Section>

                            <Section title={translate('offer.detail.attachments')}>
                                <OfferDetailAttachments offer={offer} />
                            </Section>
                        </Box>

                        <Box display="flex" flexDirection="column" gap={9}>
                            <Section title={translate('offer.detail.position')}>
                                <OfferPositions offer={offer} />
                            </Section>

                            <Box display="flex" flexDirection="column" gap={5}>
                                <Text variant="subtitle1" as="h2">
                                    {translate('offer.detail.chat')}
                                </Text>

                                <Box
                                    borderStyle="solid"
                                    borderWidth="1px"
                                    borderColor="border.muted"
                                    minHeight="350px"
                                    maxHeight="600px"
                                    overflow="hidden"
                                    height="100%"
                                >
                                    <OfferChat offer={offer} />
                                </Box>
                            </Box>
                        </Box>
                    </OfferDetailLayoutGrid>
                </Box>
            </PageGrid>
        </Page>
    );
};
