import { createFileRoute } from '@tanstack/react-router';

import { AnonymousQuotationPage } from '../anonymous-quotation/pages/AnonymousQuotationPage';
import { DrawerAppWrapper } from '../cdl/Drawer/DrawerAppWrapper';

const AnonymousQuotationRoute = () => {
    return (
        <DrawerAppWrapper>
            <AnonymousQuotationPage />
        </DrawerAppWrapper>
    );
};

export const Route = createFileRoute('/limited-quotation/$anonymousQuotationId')({
    component: AnonymousQuotationRoute,
});
