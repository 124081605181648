import { ComponentType, ReactNode } from 'react';
import styled from 'styled-components';

import { Box } from '../../common/ui/Box';
import { get } from '../../common/ui/get';
import { theme } from '../../common/ui/theme';
import { Text } from '../Text/Text';

const IconWrapper = styled.div`
    background-color: ${get('colors.background.subtle')};
    padding: ${get('space.4')}px;
    border-radius: 8px;
`;

interface StatePlaceholderProps {
    title: string;
    description: string;
    Icon: ComponentType<{ width?: number; height?: number; color?: string }>;
    action?: ReactNode;
}

export const StatePlaceholder = ({ title, description, Icon, action }: StatePlaceholderProps) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="flex-start" maxWidth="344px">
            <IconWrapper>
                <Icon height={24} width={24} color={theme.colors.foreground.subtle} />
            </IconWrapper>

            <Text variant="title" marginTop={5}>
                {title}
            </Text>

            <Text variant="body" marginTop={2} marginBottom={6} color="foreground.subtle">
                {description}
            </Text>

            {action ? action : null}
        </Box>
    );
};
