import css from '@styled-system/css';
import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { Box } from '../../common/ui/Box';

import { StyledMessage } from './StyledMessage';

const OutboundMessage = styled(StyledMessage)(
    css({
        alignSelf: 'flex-end',
        backgroundColor: 'mellow-teal.3',
    })
);

const InboundMessage = styled(StyledMessage)(
    css({
        alignSelf: 'flex-start',
        backgroundColor: 'dark-blue.4',
    })
);

const SystemMessage = styled(StyledMessage)(
    css({
        backgroundColor: 'clear-blue.4',
        color: 'dark-blue.1',
        marginLeft: 'auto',
        marginRight: 'auto',
    })
);

const OutboundSystemMessage = styled(StyledMessage)(
    css({
        backgroundColor: 'dark-blue.7',
        color: 'dark-blue.1',
        alignSelf: 'flex-end',
    })
);

const InboundSystemMessage = styled(StyledMessage)(
    css({
        backgroundColor: 'dark-blue.7',
        color: 'dark-blue.1',
        alignSelf: 'flex-start',
    })
);

const MessageText = styled(Box)(
    css({
        whiteSpace: 'break-spaces',
        '&::after': {
            // we use this to add some space so the time does not overlap with long text
            content:
                '" \\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0\\00a0"',
            display: 'inline',
        },
    })
);

const MessageTime = styled(Box)(
    css({
        position: 'absolute',
        right: '7px',
        bottom: '3px',
        fontSize: '0',
        fontStyle: 'italic',
    })
);

const getMessageType = (userType, message) => {
    let userScope = userType;
    if (userScope === 'ADMIN') {
        userScope = 'CUSTOMER';
    }

    if (message.senderType === 'SYSTEM') return SystemMessage;

    const isOutbound = message.receiverType !== userScope;

    if (isOutbound) {
        return message.systemMessage ? OutboundSystemMessage : OutboundMessage;
    }

    return message.systemMessage ? InboundSystemMessage : InboundMessage;
};

const getSenderNameBySenderType = (message) => {
    switch (message.senderType) {
        case 'CUSTOMER':
            return message.customer.name;
        case 'SUPPLIER':
            return message.supplier.name;
        case 'USER':
            return `${message.user?.firstname} ${message.user?.lastname}`;
        case 'SYSTEM':
        default:
            return translate('global.system');
    }
};

export const OfferChatMessage = ({ message }) => {
    const role = useRole();

    const MessageType = getMessageType(role.user.userType, message);

    return (
        <MessageType>
            <Text weight="bold">{getSenderNameBySenderType(message)}</Text>
            <MessageText
                style={{
                    fontStyle: message.systemMessage ? 'italic' : 'normal',
                }}
            >
                {message.message}
            </MessageText>
            <MessageTime>
                {formatDateTime({
                    date: message.dateCreated,
                    format: 'HH:mm',
                })}
            </MessageTime>
        </MessageType>
    );
};
