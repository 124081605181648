import { UseQueryResult } from '@tanstack/react-query';
import { useNavigate, useParams } from '@tanstack/react-router';
import { AxiosError } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';

import { SamplekitMessage } from '../../../../types/SamplekitMessage';
import { SurchargeMessage } from '../../../../types/SurchargeMessage';
import { SurchargeType } from '../../../../types/SurchargeType';
import { Button } from '../../../cdl/Button/Button';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { AdvancedError } from '../../../common/Error/AdvancedError';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Item } from '../../../common/models/item.model';
import { ItemModel } from '../../../common/models/ItemModel';
import { Samplekit } from '../../../common/models/samplekit.model';
import { Surcharge } from '../../../common/models/surcharge.model';
import { createOverlay } from '../../../common/Overlay/Overlay';
import { OverlayHeader } from '../../../common/Overlay/OverlayHeader';
import { Box } from '../../../common/ui/Box';
import { useValidateLubesOfferForm } from '../../hooks/useValidateLubesOfferForm';
import { Offer } from '../../model/offer.model';
import { OfferModel } from '../../model/OfferModel';
import { useOfferEdit } from '../hooks/useOfferEdit';
import { useOfferView } from '../hooks/useOfferView';

import { OfferEditForm } from './components/OfferEditForm';

export const LubesOfferEditFlow = () => {
    return createOverlay(<LubesOfferEdit />);
};

const LubesOfferEdit = () => {
    const role = useRole();
    const navigate = useNavigate();
    const { id } = useParams({ from: '/_app/_lubes/offer/$id_/edit' });
    // @ts-ignore
    const offerQuery: UseQueryResult<OfferModel> = useOfferView(id);
    const offerEditMutation = useOfferEdit();
    const { addErrorToast } = useToasts();
    const formikValidate = useValidateLubesOfferForm();

    const documentTitle = offerQuery.data
        ? translate('page.offerEdit', {
              offerNumber: offerQuery.data.offerNumber,
          })
        : undefined;

    useDocumentTitle(documentTitle);

    const onCancelClick = () => {
        navigate({ to: '/offer/$id', params: { id } });
    };

    const onSubmit = async (values: OfferModel, formikHelpers: FormikHelpers<OfferModel>) => {
        try {
            await offerEditMutation.mutateAsync({
                offerId: values.id,
                offer: {
                    ...values,
                    noticeDays: values.noticeDays || undefined,
                    vendorReference: values.vendorReference || undefined,
                    buyerReference: values.buyerReference || undefined,
                    paymentTermReferenceDays: values.paymentTermReferenceDays
                        ? Number(values.paymentTermReferenceDays)
                        : undefined,
                    items: values.items?.map((item) => ({
                        ...item,
                        productId: item.productId || undefined,
                        price:
                            item.price?.value !== undefined && item.price?.value?.toString() !== ''
                                ? { value: Number(item.price.value), currency: 'USD' }
                                : undefined,
                        units: Number(item.units),
                        unitSize: Number(item.unitSize),
                    })),
                    samplekits: values.samplekits?.map((samplekit) => ({
                        ...samplekit,
                        value:
                            samplekit.value?.value !== undefined && samplekit.value?.value?.toString() !== ''
                                ? { value: samplekit.value.value, currency: 'USD' }
                                : undefined,
                    })),
                    surcharges: values.surcharges?.map((surcharge) => ({
                        ...surcharge,
                        surchargeType:
                            surcharge.value?.value < 0 && surcharge.surchargeType !== SurchargeType.CUSTOM
                                ? SurchargeType.CUSTOM
                                : surcharge.surchargeType,
                        value:
                            surcharge.value?.value !== undefined && surcharge.value?.value?.toString() !== ''
                                ? { value: Number(surcharge.value.value), currency: 'USD' }
                                : undefined,
                    })),
                },
            });

            navigate({ to: '/offer/$id', params: { id: values.id } });
        } catch (error) {
            const axiosError = error as AxiosError<{ errorCode: string }>;
            if (axiosError.response?.status === 400 && axiosError.response.data.errorCode === 'BUYER_REF_TAKEN') {
                addErrorToast(translate('offer.edit.lubes.buyerReferenceErrorToast'));
                formikHelpers.setFieldError('buyerReference', translate('offer.edit.lubes.buyerReferenceAlreadyUsed'));
            } else {
                addErrorToast(translate('offer.edit.lubes.errorToast'));
            }
        }
    };

    if (offerQuery.isPending) {
        return (
            <OverlayHeader onClose={onCancelClick}>
                <LoadingIndicator variant="page" />
            </OverlayHeader>
        );
    }

    if (offerQuery.isError || !offerQuery.data) {
        return (
            <OverlayHeader title={translate('offer.edit.lubes.editHeadline')} onClose={onCancelClick}>
                <AdvancedError />
            </OverlayHeader>
        );
    }

    const initialSupplierValues = new Offer({
        ...offerQuery.data,
        items: offerQuery.data.items.map((item: ItemModel) => {
            return new Item({
                ...item,
                price: { value: item.price?.value || '', currency: item.price?.currency || 'USD' },
                productId: item.productId ? item.productId : '',
            });
        }),
        surcharges: offerQuery.data.surcharges.map((surcharge: SurchargeMessage) => {
            return new Surcharge({
                ...surcharge,
                value: { value: surcharge.value?.value, currency: surcharge.value?.currency || 'USD' },
            });
        }),
        samplekits: offerQuery.data.samplekits
            ? offerQuery.data.samplekits.map((samplekit: SamplekitMessage) => {
                  return new Samplekit({
                      ...samplekit,
                      value: { value: samplekit.value?.value || '', currency: samplekit.value?.currency || 'USD' },
                  });
              })
            : undefined,
        supplyMode: offerQuery.data.supplyMode || '',
    });

    return (
        <Box paddingBottom={11} minHeight="100%">
            <Formik
                initialValues={role.isSupplier() ? (initialSupplierValues as unknown as OfferModel) : offerQuery.data}
                validate={formikValidate}
                onSubmit={onSubmit}
            >
                {(formikProps) => (
                    <Form>
                        <OverlayHeader
                            title={
                                offerQuery.data.isOrder()
                                    ? translate('offer.edit.lubes.orderHeadline', {
                                          number: offerQuery.data.offerNumber,
                                      })
                                    : translate('offer.edit.lubes.offerHeadline', {
                                          number: offerQuery.data.offerNumber,
                                      })
                            }
                            onClose={onCancelClick}
                            trailingVisual={
                                <Button emphasis="high" type="submit" isLoading={formikProps.isSubmitting}>
                                    {translate('btn.save.default')}
                                </Button>
                            }
                        >
                            <OfferEditForm />
                        </OverlayHeader>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
