import styled from 'styled-components';
import { get } from '../ui/get';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background: ${get('colors.background.default')};
`;

const EdgeWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
`;

const LeftWrapper = styled(EdgeWrapper)`
    flex-direction: row;
`;

const RightWrapper = styled(EdgeWrapper)`
    flex-direction: row-reverse;
`;

export const Header = (props) => {
    return (
        <Wrapper className={props.className}>
            <LeftWrapper>{props.leftElement}</LeftWrapper>
            {props.centerElement}
            <RightWrapper>{props.rightElement}</RightWrapper>
        </Wrapper>
    );
};
