import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

import { get } from '../../common/ui/get';

export const DropdownMenuContent = styled(RadixDropdownMenu.Content)`
    padding: ${get('space.2')}px;
    background: ${get('colors.background.default')};
    border-radius: ${get('radii.2')}px;
    border: 1px solid ${get('colors.border.muted')};
    box-shadow: ${get('shadows.large')};
    z-index: 9999;
`;
