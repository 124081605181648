import { ReactNode } from 'react';
import styled from 'styled-components';

import { LabelText } from '../../common/LabelText/LabelText';
import { Box } from '../../common/ui/Box';
import { get } from '../../common/ui/get';
import { Text } from '../Text/Text';

export const ValueFrame = styled.div`
    background: transparent;
    border-style: dashed;
    border-width: 1px;
    border-color: ${get('colors.border.muted')};
    width: 100%;
    padding: 6px 9px;
    margin: 0;
    border-radius: ${get('radii.1')}px;
    height: 38px;
    display: flex;
    gap: ${get('space.4')}px;
    align-items: center;
    justify-content: space-between;
`;

interface ReadOnlyFieldProps {
    value: string;
    label?: string;
    leadingVisual?: ReactNode;
    trailingVisual?: ReactNode;
}

export const ReadOnlyField = ({ value, label, leadingVisual, trailingVisual }: ReadOnlyFieldProps) => {
    return (
        <Box>
            {label ? <LabelText>{label}</LabelText> : null}
            <ValueFrame>
                <Box display="flex" gap={4} alignItems="center" width="100%" overflow="hidden">
                    {leadingVisual ? <div>{leadingVisual}</div> : null}
                    <Text
                        variant="body"
                        fontWeight="medium"
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: 'block',
                        }}
                    >
                        {value}
                    </Text>
                </Box>
                {trailingVisual ? <div>{trailingVisual}</div> : null}
            </ValueFrame>
        </Box>
    );
};
