import styled from 'styled-components';

import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { useToasts } from '../../cdl/Toast/useToasts';
import { ButtonGroup } from '../buttons/ButtonGroup';
import { translate } from '../helpers/translate.helper';
import { useOpenFreshchat } from '../hooks/useOpenFreshchat';
import { useRole } from '../hooks/useRole';
import { IconCopy } from '../icons/cdl/Copy';
import { IconReload } from '../icons/cdl/Reload';
import { Box } from '../ui/Box';

// We can't use the values from our theme in here, because this error component is used in the root route

const StyledPre = styled.pre`
    font-variant-numeric: lining-nums tabular-nums stacked-fractions slashed-zero;
    font-family:
        Roboto Mono,
        monospace;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    color: #5f7d8b; // foreground.subtle
    border: none;
    background: none;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px;
`;

const CopyButton = styled(Button).attrs({
    emphasis: 'high',
    leadingVisual: <IconCopy height={16} width={16} />,
})`
    position: absolute;
    bottom: 8px;
    right: 8px;
`;

interface AdvancedErrorProps {
    error?: unknown;
    stack?: string;
    resetError?: () => void;
}

export const AdvancedError = ({ error, stack, resetError = () => window.location.reload() }: AdvancedErrorProps) => {
    const { addToast, addErrorToast } = useToasts();
    const role = useRole();
    const openFreshchat = useOpenFreshchat();

    const cleanedError = error ? error.toString().replace(/^Error:\s*/, '') : undefined;

    const copyToClipboard = async (errorText: string) => {
        try {
            await navigator.clipboard.writeText(errorText);
            addToast(translate('errorView.copySuccess'));
        } catch {
            addErrorToast(translate('errorView.copyError'));
        }
    };

    let errorMessage = '';

    if (cleanedError) {
        errorMessage = cleanedError;
    }

    if (stack) {
        errorMessage += stack;
    }

    return (
        <Box display="flex" justifyContent="center" paddingTop="256px">
            <Box display="grid" gap="32px" width="560px">
                <Box display="grid" gap="16px">
                    <Text variant="display">{translate('errorView.title')}</Text>
                    <Text variant="body" color="#466573">
                        {translate('errorView.description')}
                    </Text>
                </Box>

                {cleanedError || stack ? (
                    <div>
                        <Text variant="fieldLabel" color="#466573">
                            {translate('errorView.errorMessage')}
                        </Text>
                        <Box
                            position="relative"
                            height="84px"
                            width="560px"
                            overflow="hidden"
                            backgroundColor="#F8FBFD" //background.subtle
                            borderRadius="4px"
                            border="1px solid"
                            borderColor="#CEE2EA" // border.muted
                        >
                            <StyledPre>
                                {cleanedError ? cleanedError.toString() : null}
                                {stack || null}
                            </StyledPre>
                            <CopyButton onClick={() => copyToClipboard(errorMessage)}>
                                {translate('errorView.copy')}
                            </CopyButton>
                        </Box>
                    </div>
                ) : null}

                <ButtonGroup>
                    <Button emphasis="high" onClick={resetError} leadingVisual={<IconReload height={16} width={16} />}>
                        {translate('errorView.reloadPage')}
                    </Button>

                    {!role.isAdmin() ? (
                        <Button emphasis="low" onClick={openFreshchat}>
                            {translate('errorView.getHelp')}
                        </Button>
                    ) : null}
                </ButtonGroup>
            </Box>
        </Box>
    );
};
