import { OrderTabEnum } from '../../orderTabsHelper';

import { DeliveredQuickFilter } from './DeliveredQuickFilter';
import { EnquiryQuickFilter } from './EnquiryQuickFilter';
import { OrderQuickFilter } from './OrderQuickFilter';
import { PartialQuickFilterTotals, QuickFilterValue } from './QuickFilterValueTypes';

interface OrderQuickFilterProps {
    tab: string;
    onChange: (filterValue: QuickFilterValue) => void;
    totals?: PartialQuickFilterTotals;
    approvalEnabled: boolean;
}

export const QuickFilter = (props: OrderQuickFilterProps) => {
    if (!props.totals) {
        return null;
    }

    switch (props.tab) {
        case OrderTabEnum.ENQUIRED:
            return (
                <EnquiryQuickFilter
                    onChange={props.onChange}
                    totals={props.totals}
                    approvalEnabled={props.approvalEnabled}
                />
            );
        case OrderTabEnum.ORDERED:
            return (
                <OrderQuickFilter
                    onChange={props.onChange}
                    totals={props.totals}
                    approvalEnabled={props.approvalEnabled}
                />
            );
        case OrderTabEnum.DELIVERED:
            return props.approvalEnabled ? (
                <DeliveredQuickFilter onChange={props.onChange} totals={props.totals} />
            ) : null;
        default:
            return null;
    }
};
