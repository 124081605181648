import invariant from 'tiny-invariant';

import { UserType } from '../../../types/UserType';
import { queries } from '../../common/api/queryKeys/queries';
import { JWTHelper } from '../../common/JwtHelper';
import { initialize } from '../../common/launchDarkly/launchDarklyClient';
import { AblyService } from '../../common/services/AblyService';
import { queryClient } from '../../lib/queryClient';

import { getCustomerCompanies, getCustomerGroup } from './customer.api';
import { getUser, login, renew } from './login.api';
import { RoleService } from './RoleService';
import { SessionStorageService } from './SessionStorageService';
import { getSupplierCompanies, getSupplierGroup } from './supplier.api';

let instance = null;

export class SessionService {
    static getInstance() {
        if (!instance) {
            instance = new SessionService();
        }
        return instance;
    }

    async loadRoleInformation() {
        invariant(this.isAuthenticated(), 'called loadRoleInformation with an unauthenticated user');

        const user = await getUser();
        const isCustomer = user.userType === UserType.CUSTOMER;

        let group = null;
        let companies = null;

        if (user.groupId) {
            if (isCustomer) {
                group = await getCustomerGroup(user.groupId);
                companies = await getCustomerCompanies();
            } else {
                group = await getSupplierGroup(user.groupId);
                companies = await getSupplierCompanies();
            }
        }

        const role = RoleService.getInstance().create(this.getToken(), user, group, companies);

        try {
            await initialize(role);
            AblyService.connect();
        } catch {
            // Fail silently in case LaunchDarkly has issues.
        }

        this.propagateAuthenticated();

        return role;
    }

    async authenticate(credentials) {
        await this.saveTokenAndLoadUserData(login(credentials.username, credentials.password));
    }

    async renewSession() {
        RoleService.getInstance().reset();

        return this.saveTokenAndLoadUserData(renew());
    }

    async saveTokenAndLoadUserData(tokenPromise) {
        try {
            const response = await tokenPromise;
            const token = response.data.accessToken;
            SessionStorageService.saveToken(token);

            const role = await this.loadRoleInformation();
            queryClient.setQueryData(queries.role.information.queryKey, role);
            return role;
        } catch (error) {
            return this.handleAuthenticateError(error);
        }
    }

    propagateAuthenticated() {
        RoleService.getInstance().authenticated();
    }

    handleAuthenticateError(error) {
        SessionStorageService.resetToken();
        return Promise.reject(error);
    }

    getToken() {
        if (SessionStorageService.getToken()) {
            return JWTHelper.decode(SessionStorageService.getToken());
        }
        return [];
    }

    isAuthenticated() {
        if (!SessionStorageService.getToken() || JWTHelper.hasTokenExpired(SessionStorageService.getToken())) {
            return false;
        }

        return SessionStorageService.getToken() !== null;
    }
}
