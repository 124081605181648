import { PendingApprovalRequestMessage } from '../../../../types/ApprovalRequestMessage';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { Paragraph } from '../../../common/ui/Paragraph';
import { MetaData } from '../MetaData';

import { ApprovalRequestContentWrapper } from './ApprovalRequestContentWrapper';

interface PendingApprovalRequestContentProps {
    approvalRequest: PendingApprovalRequestMessage;
}

export const PendingApprovalRequestContent = (props: PendingApprovalRequestContentProps) => {
    const requestText = props.approvalRequest.requestMessage
        ? translate('approvalRequest.dropdown.createdHeadlineComment', {
              requestMessage: props.approvalRequest.requestMessage,
          })
        : translate('approvalRequest.dropdown.createdHeadlineNoComment');

    return (
        <ApprovalRequestContentWrapper>
            <Paragraph>{requestText}</Paragraph>
            <MetaData>
                {translate('approvalRequest.dropdown.changeInformation', {
                    date: formatDateTime({
                        date: props.approvalRequest.dateCreated,
                    }),
                    author: `${props.approvalRequest.createdByUser.firstname} ${props.approvalRequest.createdByUser.lastname}`,
                })}
            </MetaData>
        </ApprovalRequestContentWrapper>
    );
};
