import styled from 'styled-components';

import { Text } from '../Text/Text';
import { formatNumber } from '../../common/helpers/formatNumber.helper';
import { translate } from '../../common/helpers/translate.helper';
import { get } from '../../common/ui/get';

import { Tab } from './components/Tab';
import { Box } from '../../common/ui/Box';

const TabBar = styled.div`
    height: 32px;
    margin-bottom: 8px;
    border-bottom: 1px solid ${get('colors.border.muted')};
    position: relative;
`;

export const Tabs = ({ selectedContext, onSelect, totals }) => {
    const possibleContexts = Object.keys(totals);

    return (
        <TabBar>
            <Box display="flex" position="absolute" top={0} left="-8px">
                {possibleContexts.map((context) => {
                    return (
                        <Tab key={context} onClick={() => onSelect(context)} active={context === selectedContext}>
                            {translate(`contextSwitch.${context}.title`)}
                            {typeof totals[context] === 'number' && totals[context] > 0 ? (
                                <Text variant="extraSmall" color="foreground.subtle" fontWeight="medium">
                                    {formatNumber({
                                        number: totals[context],
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    })}
                                </Text>
                            ) : null}
                        </Tab>
                    );
                })}
            </Box>
        </TabBar>
    );
};
