import { createQueryKeys } from '@lukemorales/query-key-factory';

import { getCustomers, GetCustomersParams } from '../clients/company.api';

export const customers = createQueryKeys('customers', {
    list: (params: GetCustomersParams) => ({
        queryKey: [{ params }],
        queryFn: () => getCustomers(params),
    }),
    settings: (customerIds: string[]) => ({
        queryKey: [{ customerIds }],
    }),
});
