import styled from 'styled-components';

interface RadioCardGroupScrollMaskProps {
    fadeLeftEnd: boolean;
    fadeRightEnd: boolean;
    hasSecondaryLabel?: boolean;
}

export const RadioCardGroupScrollMask = styled.div<RadioCardGroupScrollMaskProps>`
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 64px;
        height: ${(props) => (props.hasSecondaryLabel ? 64 : 40)}px;
        top: 0;
        left: 0;
        pointer-events: none;

        transition: opacity 200ms ease-in-out;
        opacity: ${(props) => (props.fadeLeftEnd ? 1 : 0)};
        background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    }
    
    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 64px;
        height: ${(props) => (props.hasSecondaryLabel ? 64 : 40)}px;
        right: 0;
        top: 0;
        pointer-events: none;

        transition: opacity 200ms ease-in-out;
        opacity: ${(props) => (props.fadeRightEnd ? 1 : 0)};
        background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
`;
