import styled from 'styled-components';

import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { translate } from '../helpers/translate.helper';
import { useRole } from '../hooks/useRole';
import { IconSettings } from '../icons/cdl/Settings';
import { IconUsers } from '../icons/cdl/Users';
import { NavigationItem } from '../Sidebar/components/NavigationItem';
import { Box } from '../ui/Box';

import { GlobalSearch } from './GlobalSearch';
import { Notifications } from './Notifications/Notifications';
import { UserMenu } from './UserMenu';
import { TopBarButton } from './TopBarButton';
import { IconCompass } from '../icons/cdl/Compass';

const TopbarNavigationItem = styled(NavigationItem)`
    border-radius: 100%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        margin-right: 0;
    },
`;

export const Topbar = () => {
    const role = useRole();
    const userSpecifics = [];

    if (role.isSupplier() && role.hasAnyAdminRights()) {
        userSpecifics.push(
            <Tooltip label={translate('navigation.tooltips.suppliergroup')} key="supplier-group">
                <span>
                    <TopbarNavigationItem to="/supplier-group/$id" params={{ id: role.group.id }}>
                        <IconSettings />
                    </TopbarNavigationItem>
                </span>
            </Tooltip>
        );
    }

    if (role.isCustomer() && role.hasAnyAdminRights() && !role.usesCloselinkLite()) {
        userSpecifics.push(
            <Tooltip label={translate('navigation.tooltips.customergroup')} key="customer-group">
                <span>
                    <TopbarNavigationItem to="/customer-group/$id" params={{ id: role.group.id }}>
                        <IconSettings />
                    </TopbarNavigationItem>
                </span>
            </Tooltip>
        );
    }

    if (role.isAdmin() || (role.hasAnyAdminRights() && !role.usesCloselinkLite())) {
        userSpecifics.push(
            <Tooltip label={translate('navigation.tooltips.users')} key="users">
                <span>
                    <TopbarNavigationItem to="/users">
                        <IconUsers />
                    </TopbarNavigationItem>
                </span>
            </Tooltip>
        );
    }

    const isLubesOnly = !role.getPossibleContexts().includes('FUEL');

    return (
        <Box
            display="flex"
            paddingLeft="28px"
            paddingRight="40px"
            backgroundColor="white"
            height="60px"
            alignItems="center"
            position="fixed"
            top="0"
            left="220px"
            right="0"
            // this was the topbar's previous z-index, using the same index for
            // compatibility:
            zIndex="2025"
        >
            <Box display="flex" width="100%" alignItems="center" justifyContent="space-between" gap={5}>
                <Box width="420px">
                    <GlobalSearch />
                </Box>

                <Box display="flex" alignItems="center" gap={4}>
                    {userSpecifics.length ? userSpecifics : null}

                    <TopBarButton onClick={() => Productlane?.toggle()}>
                        <IconCompass />
                    </TopBarButton>

                    {!role.isAdmin() && !isLubesOnly ? <Notifications /> : null}

                    <UserMenu />
                </Box>
            </Box>
        </Box>
    );
};
