import { useSearch } from '@tanstack/react-router';

import { RadioCardGroup } from '../../../../cdl/RadioCard/RadioCardGroup';
import { formatNumber } from '../../../../common/helpers/formatNumber.helper';
import { translate } from '../../../../common/helpers/translate.helper';

import { PartialQuickFilterTotals, QuickFilterValue, QuickFilterValueEnum } from './QuickFilterValueTypes';

const createOrderOptions = (totals: PartialQuickFilterTotals, approvalEnabled: boolean) => {
    const getTotals = (key: QuickFilterValue) => {
        return formatNumber({ number: totals[key] || 0 });
    };
    const all = {
        primaryLabel: translate('order.quickfilter.all'),
        secondaryLabel: getTotals(QuickFilterValueEnum.ALL),
        value: QuickFilterValueEnum.ALL,
    };

    const notApproved = {
        primaryLabel: translate('order.quickfilter.notApproved'),
        secondaryLabel: getTotals(QuickFilterValueEnum.ORDER_NOT_APPROVED),
        value: QuickFilterValueEnum.ORDER_NOT_APPROVED,
    };

    const notAcknowledged = {
        primaryLabel: translate('order.quickfilter.notAcknowledged'),
        secondaryLabel: getTotals(QuickFilterValueEnum.ORDER_NOT_ACKNOWLEDGED),
        value: QuickFilterValueEnum.ORDER_NOT_ACKNOWLEDGED,
    };

    const notConfirmed = {
        primaryLabel: translate('order.quickfilter.notConfirmed'),
        secondaryLabel: getTotals(QuickFilterValueEnum.ORDER_NOT_CONFIRMED),
        value: QuickFilterValueEnum.ORDER_NOT_CONFIRMED,
    };

    if (approvalEnabled) {
        return [all, notApproved, notAcknowledged, notConfirmed];
    }

    return [all, notAcknowledged, notConfirmed];
};

interface OrderQuickFilterProps {
    onChange: (filterValue: QuickFilterValue) => void;
    totals: PartialQuickFilterTotals;
    approvalEnabled: boolean;
}

export const OrderQuickFilter = (props: OrderQuickFilterProps) => {
    const search = useSearch({ from: '/_app/_lubes/orders' });
    const options = createOrderOptions(props.totals, props.approvalEnabled);

    return (
        <RadioCardGroup<QuickFilterValue>
            options={options}
            onChange={(value) => {
                props.onChange(value);
            }}
            value={search.quickFilter}
        />
    );
};
