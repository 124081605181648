import styled from 'styled-components';

import { Text } from '../../../../cdl/Text/Text';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { translate } from '../../../../common/helpers/translate.helper';
import { Characters } from '../../../../common/helpers/unicode.helper';
import { useRole } from '../../../../common/hooks/useRole';
import { IconClipboardText } from '../../../../common/icons/cdl/ClipboardText';
import { Box } from '../../../../common/ui/Box';

const CopyApiKeyButton = styled.button`
    padding: 0;

    svg {
        transition: opacity 75ms ease-in-out;
        opacity: 0;
    }

    &:hover {
        svg {
            opacity: 1;
        }
    }
`;

interface ApiKeyDisplayProps {
    apiKey: string;
}

const createTruncatedApiKey = (apiKey: string) => {
    return `${apiKey.slice(0, 3)}${Characters.HORIZONTAL_ELLIPSIS}${apiKey.slice(-3)}`;
};

export const ApiKeyDisplay = ({ apiKey }: ApiKeyDisplayProps) => {
    const { addToast, addErrorToast } = useToasts();
    const role = useRole();

    const copyToClipboard = (text: string) => {
        try {
            navigator.clipboard.writeText(text);
            addToast(translate('apiKey.copied'));
        } catch {
            addErrorToast(translate('apiKey.copyFailed'));
        }
    };

    if (!role.isAdmin()) {
        return <Text variant="small">{createTruncatedApiKey(apiKey)}</Text>;
    }

    return (
        <CopyApiKeyButton onClick={() => copyToClipboard(apiKey)}>
            <Box display="flex" gap={2} alignItems="center">
                <Text variant="small">{createTruncatedApiKey(apiKey)}</Text>
                <IconClipboardText height={16} width={16} />
            </Box>
        </CopyApiKeyButton>
    );
};
