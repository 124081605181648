import Skeleton from 'react-loading-skeleton';

import { ProductContext } from '../../../../types/ProductContext';
import { GenericError } from '../../../common/Error/GenericError';
import { translate } from '../../../common/helpers/translate.helper';
import { Section } from '../../../common/Section/Section';
import { SettingsSection } from '../../SettingsSection';

import { CustomerNotificationSettings } from './CustomerNotificationSettings';
import { FuelCustomerApprovalSettings } from './FuelCustomerApprovalSettings';
import { FuelCustomerIntegrationSettings } from './integrations/FuelCustomerIntegrationSettings';
import { useCustomerSettings } from './useCustomerSettings';

interface FuelCustomerSettingsProps {
    customerId: string;
}

export const FuelCustomerSettings = (props: FuelCustomerSettingsProps) => {
    const customerSettingsQuery = useCustomerSettings(props.customerId);

    if (customerSettingsQuery.isPending) {
        return (
            <Section title={translate('customers.details.settings')}>
                <SettingsSection label={<Skeleton width="120px" height="16px" />}>
                    <Skeleton width="80%" height="16px" />
                    <Skeleton width="80%" height="16px" />
                    <Skeleton width="80%" height="16px" />
                    <Skeleton width="80%" height="16px" />
                </SettingsSection>
                <SettingsSection label={<Skeleton width="120px" height="16px" />}>
                    <Skeleton width="80%" height="16px" />
                </SettingsSection>
                <SettingsSection label={<Skeleton width="120px" height="16px" />}>
                    <Skeleton width="80%" height="16px" />
                </SettingsSection>
            </Section>
        );
    }

    if (customerSettingsQuery.isError) {
        return (
            <Section title={translate('customers.details.settings')}>
                <GenericError />
            </Section>
        );
    }

    return (
        <Section title={translate('customers.details.settings')}>
            <CustomerNotificationSettings
                type={ProductContext.FUEL}
                customerSettings={customerSettingsQuery.data}
                customerId={props.customerId}
            />
            <FuelCustomerApprovalSettings customerSettings={customerSettingsQuery.data} customerId={props.customerId} />
            <FuelCustomerIntegrationSettings customerId={props.customerId} />
        </Section>
    );
};
