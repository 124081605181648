import { useQuery } from '@tanstack/react-query';

import { getOrdersTotal } from '../../common/api/clients/gateway.api';
import { queryKeys } from '../../common/api/queryKeys';

import { orderTabOfferStateFilter, OrderTabEnum, orderTabStateFilter } from './orderTabsHelper';

export const useOrderPaginationTotals = ({ searchQuery, customerIds, supplierIds, types }) => {
    const fetchTotals = ({ searchQuery, customerIds, supplierIds, types }) => {
        const orderTabs = Object.values(OrderTabEnum);

        return Promise.all(
            orderTabs.map((tab) =>
                getOrdersTotal({
                    searchQuery,
                    customerIds,
                    supplierIds,
                    types,
                    offerStates: orderTabOfferStateFilter[tab],
                    states: orderTabStateFilter[tab],
                })
            )
        )
            .then((values) => values.map((value) => value.count))
            .then((ordersTotals) => {
                return orderTabs.reduce((acc, value, index) => {
                    acc[value] = ordersTotals[index];
                    return acc;
                }, {});
            });
    };

    return useQuery({
        queryKey: queryKeys.ordersTotals({
            searchQuery,
            customerIds,
            supplierIds,
            types,
        }),

        queryFn: () =>
            fetchTotals({
                searchQuery,
                customerIds,
                supplierIds,
                types,
            }),
        refetchOnWindowFocus: false,
    });
};
