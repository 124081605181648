import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import styled from 'styled-components';

import { get } from '../../../common/ui/get';

export const RadioCard = styled(RadixRadioGroup.Item)`
    min-width: 168px;
    padding: ${get('space.4')}px ${get('space.5')}px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    flex: 1 0 0;

    border: none;
    border-radius: ${get('radii.2')}px;
    box-shadow: inset 0 0 0 1px ${get('colors.border.muted')};
    background: ${get('colors.background.default')};

    * {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        text-align: left;
    }

    transition: all 100ms ease-in-out;

    &:hover,
    &:focus {
        box-shadow: inset 0 0 0 1px ${get('colors.accent.foreground')};
    }

    &[data-state='checked'] {
        box-shadow: inset 0 0 0 2px ${get('colors.accent.foreground')};
        background-color: ${get('colors.accent.background')};

        * {
            color: ${get('colors.accent.foreground')};
        }
    }
`;
