import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';

import { AdvancedError } from './common/Error/AdvancedError';

interface ErrorBoundaryProps {
    children: ReactNode;
}

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
    return (
        <Sentry.ErrorBoundary
            fallback={({ error, componentStack, resetError }) => (
                <AdvancedError error={error} stack={componentStack} resetError={resetError} />
            )}
        >
            {children}
        </Sentry.ErrorBoundary>
    );
};
