import { translate } from '../../../common/helpers/translate.helper';
import { IconFilter } from '../../../common/icons/cdl/Filter';
import { Box } from '../../../common/ui/Box';
import { StatePlaceholder } from '../../StatePlaceholder/StatePlaceholder';

export const NoFilterResults = () => {
    return (
        <Box marginTop="124px" display="flex" justifyContent="center">
            <StatePlaceholder
                title={translate('global.emptyFilterResult.noMatchingResults')}
                description={translate('global.emptyFilterResult.tryDifferentFilter')}
                Icon={IconFilter}
            />
        </Box>
    );
};
