import { useMutation } from '@tanstack/react-query';
import FileDownload from 'js-file-download';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { downloadVesselRequisitionForVessel } from '../../../common/api/clients/vesselRequisition.api';
import { extractFileName } from '../../../common/helpers/extractFileName.helper';
import { translate } from '../../../common/helpers/translate.helper';

export const useVesselRequisitionDownload = () => {
    const { addLoadingToast } = useToasts();
    const { mutateAsync: executeVesselRequisitionForVesselDownload } = useMutation({
        mutationFn: downloadVesselRequisitionForVessel,
    });

    return async (vesselId: string) => {
        return addLoadingToast(
            () =>
                executeVesselRequisitionForVesselDownload(vesselId, {
                    onSuccess: (response) =>
                        FileDownload(
                            response.data,
                            extractFileName(response.headers['content-disposition'] || '', 'VesselRequisition.xlsx')
                        ),
                }),
            translate('vessel.requisitionForm.downloading'),
            translate('vessel.requisitionForm.downloadSuccess'),
            translate('vessel.requisitionForm.downloadError')
        );
    };
};
