import { IconCornerDownRight } from '../../common/icons/cdl/CornerDownRight';
import { Box } from '../../common/ui/Box';
import { Paragraph } from '../../common/ui/Paragraph';

import { MetaData } from './MetaData';

interface ApprovalResponseProps {
    response: string;
    metaData: string;
}

export const ApprovalResponse = (props: ApprovalResponseProps) => {
    return (
        <Box display="flex" gap={5} marginTop={5} alignItems="center">
            <div>
                <IconCornerDownRight />
            </div>
            <div>
                <Paragraph>{props.response}</Paragraph>
                <MetaData>{props.metaData}</MetaData>
            </div>
        </Box>
    );
};
