import { useRole } from './useRole';

export const useOpenFreshchat = () => {
    const role = useRole();
    return async () => {
        try {
            const isExistingUserResult = await window.fcWidget.user.isExists();

            if (role && !isExistingUserResult.data) {
                window.fcWidget.setExternalId(role.user.id);
            }
            window.fcWidget.open();
        } catch {
            window.open('mailto:hello@closelink.com');
        }
    };
};
