import { useNavigate, useSearch } from '@tanstack/react-router';

import { Tabs } from '../../cdl/Tabs/Tabs';
import { useProductContext } from '../../common/hooks/useProductContext';

import { StatisticTab } from './StatisticTab';

export const CustomerStatisticsTabSwitch = () => {
    const { isFuel } = useProductContext();
    const search = useSearch({ from: isFuel ? '/_app/fuel/statistics' : '/_app/_lubes/statistics' });

    const navigate = useNavigate({ from: isFuel ? '/fuel/statistics' : '/statistics' });

    return (
        <Tabs
            selectedContext={search.tab}
            onSelect={(tab: StatisticTab) => {
                navigate({
                    search: (previousSearch) => ({
                        ...previousSearch,
                        tab,
                    }),
                });
            }}
            totals={{
                [StatisticTab.GENERAL]: null,
                [StatisticTab.VESSELS]: null,
                [StatisticTab.SUPPLIERS]: null,
                [StatisticTab.PORTS]: null,
                [StatisticTab.PRODUCTS]: null,
                [StatisticTab.PRODUCT_GROUPS]: null,
            }}
        />
    );
};
