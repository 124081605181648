import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { get } from '../ui/get';

// Component definition until the CDL component is implemented

const basicHeaderWithLineStyles = css`
    padding-bottom: 12px;
    border-bottom: 1px solid ${get('colors.border.muted')};
`;

const AdvancedHeaderWithLine = styled.div`
    display: grid;
    grid-template-columns: auto max-content;
    grid-template-rows: auto auto;
    column-gap: ${get('space.5')}px;
    row-gap: ${get('space.2')}px;

    ${basicHeaderWithLineStyles}
`;

const SimpleHeaderWithLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${get('space.5')}px;

    ${basicHeaderWithLineStyles}
`;

interface HeaderWithLineProps {
    description?: string;
    children: ReactNode;
}

export const HeaderWithLine = ({ description, children }: HeaderWithLineProps) => {
    if (description) {
        return (
            <AdvancedHeaderWithLine>
                {children}
                <Text variant="small" color="foreground.muted">
                    {description}
                </Text>
            </AdvancedHeaderWithLine>
        );
    }

    return <SimpleHeaderWithLine>{children}</SimpleHeaderWithLine>;
};
