import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconCompass = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M4.062 11H5a1 1 0 1 1 0 2h-.938A8.004 8.004 0 0 0 11 19.938V19a1 1 0 1 1 2 0v.938A8.004 8.004 0 0 0 19.938 13H19a1 1 0 1 1 0-2h.938A8.004 8.004 0 0 0 13 4.062V5a1 1 0 1 1-2 0v-.938A8.004 8.004 0 0 0 4.062 11ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm14.707-4.707a1 1 0 0 1 .242 1.023l-2 6a1 1 0 0 1-.633.633l-6 2a1 1 0 0 1-1.265-1.265l2-6a1 1 0 0 1 .633-.633l6-2a1 1 0 0 1 1.023.242Zm-5.916 3.498-1.21 3.628 3.628-1.21 1.21-3.628-3.628 1.21Z"
            clipRule="evenodd"
        />
    </svg>
);
