import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { queryClient } from '../lib/queryClient';
import { LoadingApp } from '../LoadingApp';
import { LoginPage } from '../login/components/LoginPage';
import { RoleService } from '../user/session/RoleService';
import { SessionStorageService } from '../user/session/SessionStorageService';

const LoginSearchSchema = z.object({
    email: z.string().email().optional().catch(undefined),
    redirect: z.string().optional().catch(undefined),
});

export const Route = createFileRoute('/login')({
    validateSearch: (search: Record<string, unknown>) => LoginSearchSchema.parse(search),
    onEnter: () => {
        window.Productlane?.close();
        window.fcWidget?.close();
        window.fcWidget?.user?.clear();

        SessionStorageService.resetToken();
        RoleService.getInstance().reset();
        queryClient.clear();
    },
    component: LoginPage,
    pendingComponent: LoadingApp,
});
