import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export interface CreateQuotationDocumentRequestMessage {
    offerId: string;
    customerId: string;
}

export const createQuotationDocument = async (requestBody: CreateQuotationDocumentRequestMessage): Promise<void> => {
    return authorizedAxiosInstance.post('/v1/bass-net/triggers/quotation-document', requestBody);
};

export interface CreateOrderConfirmationDocumentRequestMessage {
    offerId: string;
    customerId: string;
}

export const createOrderConfirmationDocument = async (
    requestBody: CreateOrderConfirmationDocumentRequestMessage
): Promise<void> => {
    return authorizedAxiosInstance.post('/v1/bass-net/triggers/order-confirmation-document', requestBody);
};
