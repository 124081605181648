import { useState } from 'react';

import { Button } from '../cdl/Button/Button';
import { Callout } from '../cdl/Callout/Callout';
import { Text } from '../cdl/Text/Text';
import { useToasts } from '../cdl/Toast/useToasts';
import { translate } from '../common/helpers/translate.helper';
import { Box } from '../common/ui/Box';

import { IntegrationOfferSelect } from './components/IntegrationOfferSelect';
import { useBassNetOrderConfirmationDocument } from './useBassNetOrderConfirmationDocument';
import { useBassNetQuotationDocument } from './useBassnetQuotationDocument';

export const BassNetPageContent = () => {
    return (
        <div>
            <Callout
                heading={translate('integrationsOverview.calloutBassNet.headline')}
                description={translate('integrationsOverview.calloutBassNet.description')}
            />
            <Box display="grid" rowGap={8} maxWidth="400px" marginTop={6}>
                <QuotationDocumentSection />
                <OrderConfirmationDocumentSection />
            </Box>
        </div>
    );
};

const QuotationDocumentSection = () => {
    const [state, setState] = useState({
        offerId: '',
        customerId: '',
    });
    const bassNetQuotationDocument = useBassNetQuotationDocument();
    const { addToast, addErrorToast } = useToasts();

    const createDocument = () => {
        bassNetQuotationDocument.mutate(
            {
                offerId: state.offerId,
                customerId: state.customerId,
            },
            {
                onSuccess: () => {
                    addToast(translate('integrationsOverview.quotationDocumentSuccessToast'));
                },
                onError: (error) => {
                    if (error.response?.data?.message) {
                        addErrorToast(error.response.data.message);
                    } else {
                        addErrorToast(translate('integrationsOverview.unknownError'));
                    }
                },
            }
        );
    };
    return (
        <Box display="flex" gap={4} flexDirection="column" alignItems="flex-start">
            <Text variant="title" as="h2">
                {translate('integrationsOverview.bassNetQuotationDocumentHeadline')}
            </Text>

            <Box width="100%">
                <IntegrationOfferSelect
                    label={translate('integrationsOverview.selectLabel')}
                    value={state.offerId}
                    onChange={(option) => {
                        setState({
                            offerId: option.offer.id,
                            customerId: option.offer.customerId,
                        });
                    }}
                />
            </Box>

            <Button disabled={!state.offerId || !state.customerId} onClick={createDocument}>
                {translate('integrationsOverview.createDocumentButton')}
            </Button>
        </Box>
    );
};

const OrderConfirmationDocumentSection = () => {
    const [state, setState] = useState({
        offerId: '',
        customerId: '',
    });
    const bassNetOrderConfirmationDocument = useBassNetOrderConfirmationDocument();
    const { addToast, addErrorToast } = useToasts();

    const createDocument = () => {
        bassNetOrderConfirmationDocument.mutate(
            {
                offerId: state.offerId,
                customerId: state.customerId,
            },
            {
                onSuccess: () => {
                    addToast(translate('integrationsOverview.orderConfirmationDocumentSuccessToast'));
                },
                onError: (error) => {
                    if (error.response?.data?.message) {
                        addErrorToast(error.response.data.message);
                    } else {
                        addErrorToast(translate('integrationsOverview.unknownError'));
                    }
                },
            }
        );
    };
    return (
        <Box display="flex" gap={4} flexDirection="column" alignItems="flex-start">
            <Text variant="title" as="h2">
                {translate('integrationsOverview.bassNetOrderConfirmationDocumentHeadline')}
            </Text>

            <Box width="100%">
                <IntegrationOfferSelect
                    label={translate('integrationsOverview.selectLabel')}
                    value={state.offerId}
                    onChange={(option) => {
                        setState({
                            offerId: option.offer.id,
                            customerId: option.offer.customerId,
                        });
                    }}
                />
            </Box>

            <Button disabled={!state.offerId || !state.customerId} onClick={createDocument}>
                {translate('integrationsOverview.createDocumentButton')}
            </Button>
        </Box>
    );
};
