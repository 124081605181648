import { useParams } from '@tanstack/react-router';

import { Text } from '../../../cdl/Text/Text';
import { useCustomerDetails } from '../../../common/hooks/useCustomerDetails';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../../common/Page/Page';
import { Box } from '../../../common/ui/Box';
import { AdminSettings } from '../../AdminSettings';
import { GeneralInformation } from '../../GeneralInformation';
import { AdvancedError } from '../../../common/Error/AdvancedError';
import { Button } from '../../../cdl/Button/Button';
import { translate } from '../../../common/helpers/translate.helper';
import { IconDownload } from '../../../common/icons/cdl/Download';

import { useVesselRequisitionCustomerDownload } from './useVesselRequisitionCustomerDownload';
import { FuelCustomerSettings } from './FuelCustomerSettings';
import { LubesCustomerSettings } from './LubesCustomerSettings';
import { useCustomerDetailsEdit } from './useCustomerDetailsEdit';

export const CustomerDetailPage = () => {
    const role = useRole();
    const { id } = useParams({ from: '/_app/customer/detail/$id' });

    const customerDetailQuery = useCustomerDetails(id);
    const editCustomerDetailsMutation = useCustomerDetailsEdit();
    const downloadVesselRequisitions = useVesselRequisitionCustomerDownload();

    useDocumentTitle(customerDetailQuery.data ? customerDetailQuery.data.name : null);

    if (customerDetailQuery.isPending) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (customerDetailQuery.isError) {
        return (
            <Page>
                <AdvancedError />
            </Page>
        );
    }

    const isFuelCustomer = customerDetailQuery.data.type === 'FUEL';

    const onCustomerDetailEditSubmit = (data) => {
        editCustomerDetailsMutation.mutate({ id, customerData: data });
    };

    return (
        <Page>
            <Box display="grid" gap={9} gridTemplateColumns="1fr 1fr">
                <Box gridColumn="1/3">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Text as="h1" variant="headline">
                            {customerDetailQuery.data.name}
                        </Text>

                        {role.isAdmin() ? (
                            <Button
                                onClick={() => downloadVesselRequisitions(id)}
                                leadingVisual={<IconDownload width={16} height={16} />}
                            >
                                {translate('customers.requisitionForm.downloadButton')}
                            </Button>
                        ) : null}
                    </Box>
                </Box>

                <Box gridColumn="1" gridRow="2">
                    <GeneralInformation
                        onEditSubmit={onCustomerDetailEditSubmit}
                        companyDetails={customerDetailQuery.data}
                    />

                    {role.isAdmin() ? (
                        <Box marginTop={9}>
                            <AdminSettings
                                onEditSubmit={onCustomerDetailEditSubmit}
                                companyDetails={customerDetailQuery.data}
                                companyType="CUSTOMER"
                            />
                        </Box>
                    ) : null}
                </Box>

                <Box gridColumn="2" gridRow="2">
                    {isFuelCustomer ? (
                        <FuelCustomerSettings customerId={id} />
                    ) : (
                        <LubesCustomerSettings customerId={id} />
                    )}
                </Box>
            </Box>
        </Page>
    );
};
