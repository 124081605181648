import { useCallback, useEffect, useRef, useState } from 'react';

interface ScrollEnds {
    left: boolean;
    right: boolean;
}

export const useScrollNavigation = () => {
    const initialScrollWidth = 130;
    const scrollWidth = 176;

    const containerRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const [isExceeding, setIsExceeding] = useState(false);
    const [scrolledEnds, setScrolledEnds] = useState<ScrollEnds>({
        left: true,
        right: true,
    });

    // Check if the container overflows its content.
    const updateScrollStatus = useCallback(() => {
        if (containerRef.current && contentRef.current) {
            const container = containerRef.current;
            const content = contentRef.current;
            if (container.clientWidth < content.scrollWidth) {
                setIsExceeding(true);
                setScrolledEnds({ left: true, right: false });
            } else {
                setIsExceeding(false);
                setScrolledEnds({ left: true, right: true });
            }
        }
    }, []);

    // Run an initial check after mounting.
    useEffect(() => {
        updateScrollStatus();
    }, [updateScrollStatus]);

    // Update scroll status on window resize.
    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current && contentRef.current) {
                const container = containerRef.current;
                const content = contentRef.current;
                // If overflowing and scrolled away from the start, reset scroll.
                if (isExceeding && container.scrollLeft !== 0) {
                    container.scroll({ left: 0, behavior: 'smooth' });
                    setScrolledEnds({ left: true, right: false });
                }
                // If container has grown enough to contain all content.
                if (isExceeding && container.clientWidth === content.scrollWidth) {
                    setIsExceeding(false);
                    setScrolledEnds({ left: true, right: true });
                }
                // If container becomes too small.
                if (!isExceeding && container.clientWidth < content.scrollWidth) {
                    setIsExceeding(true);
                    setScrolledEnds({ left: true, right: false });
                }
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isExceeding]);

    const scrollRight = useCallback(() => {
        if (!containerRef.current) return;
        const container = containerRef.current;

        // Handle scroll to first position.
        if (container.scrollLeft === 0) {
            container.scroll({ left: initialScrollWidth, behavior: 'smooth' });
            setScrolledEnds({ left: false, right: false });
            return;
        }

        // Check if the next scroll will reach (or exceed) the end.
        if (container.scrollWidth - container.clientWidth <= container.scrollLeft + scrollWidth) {
            container.scroll({ left: container.scrollLeft + scrollWidth, behavior: 'smooth' });
            setScrolledEnds({ left: false, right: true });
            return;
        }

        // Default case: scroll right by scrollWidth.
        container.scroll({ left: container.scrollLeft + scrollWidth, behavior: 'smooth' });
    }, [initialScrollWidth, scrollWidth]);

    const scrollLeft = useCallback(() => {
        if (!containerRef.current) return;
        const container = containerRef.current;

        // If already at the very end, calculate a custom scroll position.
        if (container.scrollLeft === container.scrollWidth - container.clientWidth) {
            const totalScrollable = container.scrollWidth - container.clientWidth;
            const calculatedLastScrollWidth =
                totalScrollable -
                initialScrollWidth -
                Math.floor((totalScrollable - initialScrollWidth) / scrollWidth) * scrollWidth;
            const newScrollLeft = totalScrollable - calculatedLastScrollWidth;

            container.scroll({ left: newScrollLeft, behavior: 'smooth' });
            setScrolledEnds({ left: false, right: false });
            return;
        }

        // If close to the start, scroll to the beginning.
        if (container.scrollLeft <= initialScrollWidth) {
            container.scroll({ left: container.scrollLeft - initialScrollWidth, behavior: 'smooth' });
            setScrolledEnds({ left: true, right: false });
            return;
        }

        // Default case: scroll left by scrollWidth.
        container.scroll({ left: container.scrollLeft - scrollWidth, behavior: 'smooth' });
    }, [initialScrollWidth, scrollWidth]);

    return { containerRef, contentRef, isExceeding, scrolledEnds, scrollLeft, scrollRight };
};
