import { useSearch } from '@tanstack/react-router';

import { RadioCardGroup } from '../../../../cdl/RadioCard/RadioCardGroup';
import { formatNumber } from '../../../../common/helpers/formatNumber.helper';
import { translate } from '../../../../common/helpers/translate.helper';

import { PartialQuickFilterTotals, QuickFilterValue, QuickFilterValueEnum } from './QuickFilterValueTypes';

const createEnquiryOptions = (totals: PartialQuickFilterTotals, approvalEnabled: boolean) => {
    const getTotals = (key: QuickFilterValue) => {
        return formatNumber({ number: totals[key] || 0 });
    };
    const all = {
        primaryLabel: translate('order.quickfilter.all'),
        secondaryLabel: getTotals(QuickFilterValueEnum.ALL),
        value: QuickFilterValueEnum.ALL,
    };

    const open = {
        primaryLabel: translate('order.quickfilter.open'),
        secondaryLabel: getTotals(QuickFilterValueEnum.ENQUIRY_OPEN),
        value: QuickFilterValueEnum.ENQUIRY_OPEN,
    };

    const notApproved = {
        primaryLabel: translate('order.quickfilter.notApproved'),
        secondaryLabel: getTotals(QuickFilterValueEnum.ENQUIRY_NOT_APPROVED),
        value: QuickFilterValueEnum.ENQUIRY_NOT_APPROVED,
    };

    if (approvalEnabled) {
        return [all, notApproved, open];
    }

    return [all, open];
};

interface EnquiryQuickFilterProps {
    onChange: (filterValue: QuickFilterValue) => void;
    totals: PartialQuickFilterTotals;
    approvalEnabled: boolean;
}

export const EnquiryQuickFilter = (props: EnquiryQuickFilterProps) => {
    const search = useSearch({ from: '/_app/_lubes/orders' });
    const options = createEnquiryOptions(props.totals, props.approvalEnabled);

    return <RadioCardGroup<QuickFilterValue> options={options} onChange={props.onChange} value={search.quickFilter} />;
};
