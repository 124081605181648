import { ApprovalRequestMessage } from '../../../../types/ApprovalRequestMessage';
import { Box } from '../../../common/ui/Box';

import { ApprovalRequestContent } from './ApprovalRequestContent';
import { ApprovalRequestStateTag } from './ApprovalRequestStateTag';

interface ApprovalRequestMenuItemProps {
    approvalRequest: ApprovalRequestMessage;
}

export const ApprovalRequestMenuItem = (props: ApprovalRequestMenuItemProps) => {
    return (
        <Box display="flex" gap={5} alignItems="center" padding={6}>
            <Box display="flex" width="100px" flexShrink={0}>
                <ApprovalRequestStateTag approvalRequest={props.approvalRequest} />
            </Box>
            <ApprovalRequestContent approvalRequest={props.approvalRequest} />
        </Box>
    );
};
