import { VesselMessage } from '../../../../types/VesselMessage';
import { Button } from '../../../cdl/Button/Button';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { TextButton } from '../../../common/buttons/TextButton';
import { GenericError } from '../../../common/Error/GenericError';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { useVesselBudgetDetails } from '../../../common/hooks/useVesselBudgetDetails';
import { IconCoin } from '../../../common/icons/cdl/Coin';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Section } from '../../../common/Section/Section';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';

import { BudgetForm } from './BudgetForm';
import { BudgetInformation } from './BudgetInformation';
import { DeleteBudgetPopupButton } from './DeleteBudgetPopupButton';
import { useVesselBudgetCreate } from './useVesselBudgetCreate';
import { useVesselBudgetDelete } from './useVesselBudgetDelete';
import { useVesselBudgetEdit } from './useVesselBudgetEdit';

export const VesselBudget = ({ vessel }: { vessel: VesselMessage }) => {
    const vesselId = vessel.id;
    const role = useRole();

    const { isPending, isError, budgetData, notFound } = useVesselBudgetDetails(vesselId);

    const { mutate: createBudget } = useVesselBudgetCreate(vesselId);
    const { mutate: updateBudget } = useVesselBudgetEdit(vesselId);
    const { mutate: deleteBudget } = useVesselBudgetDelete(vesselId);

    const { push, pop } = useDrawer();

    const onCreateSubmit = (data: any) => {
        createBudget({
            budget: { amount: data.budget.amount, currency: 'USD' },
            startDate: data.startDate,
            vesselId: vesselId,
            customerId: vessel.customerId,
        });
        pop();
    };

    const onCreateBudget = () => {
        push({
            content: (
                <BudgetForm
                    title={translate('budgets.createBudget')}
                    onSubmit={onCreateSubmit}
                    budgetData={budgetData}
                />
            ),
        });
    };

    const onEditSubmit = (data: any) => {
        updateBudget(data);
        pop();
    };

    const onEditBudget = () => {
        push({
            content: (
                <BudgetForm title={translate('budgets.editBudget')} onSubmit={onEditSubmit} budgetData={budgetData} />
            ),
        });
    };

    if (isPending) {
        return (
            <Section title={translate('budgets.title')}>
                <LoadingIndicator />
            </Section>
        );
    }

    if (notFound) {
        return (
            <Section
                title={translate('budgets.title')}
                actions={<TextButton onClick={onCreateBudget}>{translate('budgets.createBudget')}</TextButton>}
            >
                <Flex justifyContent="center" alignItems="center" height="100%">
                    <TableEmptyState text={translate('budgets.emptyState')} Icon={IconCoin} />
                </Flex>
            </Section>
        );
    }

    if (isError) {
        return (
            <Section title={translate('budgets.title')}>
                <GenericError />
            </Section>
        );
    }

    return (
        <Section
            title={translate('budgets.title')}
            actions={
                role.hasWriteRights(vessel.customerId) ? (
                    <Box display="flex" flexDirection="row" columnGap={4}>
                        <Button onClick={onEditBudget}>{translate('budgets.edit')}</Button>
                        <DeleteBudgetPopupButton onConfirm={() => deleteBudget(budgetData.id)} />
                    </Box>
                ) : null
            }
            fullHeight={false}
            paddingX={0}
            paddingY={0}
        >
            <Box paddingX={7} paddingY={6}>
                <BudgetInformation budgetData={budgetData} />
            </Box>
        </Section>
    );
};
