import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconReload = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M17.772 8c-1.63-2.365-4.61-3.523-7.515-2.778a7 7 0 1 0 8.685 7.689 1 1 0 1 1 1.983.26A9 9 0 1 1 9.759 3.285c3.484-.894 7.073.35 9.241 3.02V4a1 1 0 1 1 2 0v5a1 1 0 0 1-1 1h-5a1 1 0 1 1 0-2h2.772Z"
            clipRule="evenodd"
        />
    </svg>
);
