import styled from 'styled-components';

import { IconChevronLeft } from '../../common/icons/cdl/ChevronLeft';
import { IconChevronRight } from '../../common/icons/cdl/ChevronRight';
import { get } from '../../common/ui/get';
import { Text } from '../Text/Text';

import { RadioCard } from './components/RadioCard';
import { RadioCardGroupRoot } from './components/RadioCardGroupRoot';
import { RadioCardGroupScrollButton } from './components/RadioCardGroupScrollButton';
import { RadioCardGroupScrollMask } from './components/RadioCardGroupScrollMask';
import { useScrollNavigation } from './hooks/useScrollNavigation';

const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${get('space.4')}px;
`;

export interface RadioCardGroupOption<T extends string> {
    value: T;
    primaryLabel: string;
    secondaryLabel?: string;
}

interface RadioCardGroupProps<T extends string> {
    options: RadioCardGroupOption<T>[];
    onChange: (value: T) => void;
    value?: T;
}

export const RadioCardGroup = <T extends string>({ options, onChange, value }: RadioCardGroupProps<T>) => {
    const { containerRef, contentRef, isExceeding, scrolledEnds, scrollLeft, scrollRight } = useScrollNavigation();

    const hasSecondaryLabel = options.some((option) => option.secondaryLabel);

    return (
        <RadioCardGroupScrollMask
            fadeLeftEnd={!scrolledEnds.left}
            fadeRightEnd={!scrolledEnds.right}
            hasSecondaryLabel={hasSecondaryLabel}
        >
            <RadioCardGroupScrollButton
                visible={isExceeding && !scrolledEnds.left}
                onClick={scrollLeft}
                emphasis="medium"
                Icon={IconChevronLeft}
                style={{ left: 0 }}
                hasSecondaryLabel={hasSecondaryLabel}
            />

            <RadioCardGroupRoot onValueChange={onChange} ref={containerRef} loop={false} value={value}>
                <CardContainer ref={contentRef}>
                    {options.map((option) => (
                        <RadioCard key={option.value} value={option.value}>
                            <Text variant="fieldLabel" color="foreground.muted" fontWeight="normal">
                                {option.primaryLabel}
                            </Text>
                            {option.secondaryLabel ? (
                                <Text fontWeight="semiBold" variant="small">
                                    {option.secondaryLabel}
                                </Text>
                            ) : null}
                        </RadioCard>
                    ))}
                </CardContainer>

                <RadioCardGroupScrollButton
                    visible={isExceeding && !scrolledEnds.right}
                    onClick={scrollRight}
                    emphasis="medium"
                    Icon={IconChevronRight}
                    style={{ right: 0 }}
                    hasSecondaryLabel={hasSecondaryLabel}
                />
            </RadioCardGroupRoot>
        </RadioCardGroupScrollMask>
    );
};
