import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import z from 'zod';

import { LubesOrderOverviewPage } from '../order/OrderOverview/lubes/LubesOrderOverviewPage';
import { QuickFilterValueEnum } from '../order/OrderOverview/lubes/quickfilter/QuickFilterValueTypes';

const orderOverviewSearchSchema = z.object({
    searchQuery: fallback(z.string(), '').default(''),
    customerIds: fallback(z.array(z.string()), []).default([]),
    supplierIds: fallback(z.array(z.string()), []).default([]),
    tab: fallback(z.string(), 'enquired').default('enquired'),
    quickFilter: fallback(z.string(), QuickFilterValueEnum.ALL).default(QuickFilterValueEnum.ALL),
    page: fallback(z.number(), 0).default(0),
    sortValue: fallback(
        z.object({
            sortField: z.string(),
            sortDirection: z.string(),
        }),
        {
            sortField: 'dateDelivery',
            sortDirection: 'DESC',
        }
    ).default({
        sortField: 'dateDelivery',
        sortDirection: 'DESC',
    }),
});

export const Route = createFileRoute('/_app/_lubes/orders')({
    component: LubesOrderOverviewPage,
    validateSearch: zodSearchValidator(orderOverviewSearchSchema),
});
