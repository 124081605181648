import { Fragment } from 'react';
import styled from 'styled-components';

import { get } from '../ui/get';

const StyledParagraph = styled.p`
    font-size: ${get('fontSizes.2')}px;
    font-weight: ${get('fontWeights.normal')};
    line-height: 24px;
`;

interface MultilineParagraphProps {
    children: string;
}

export const MultilineParagraph = ({ children }: MultilineParagraphProps) => {
    const lines = children.split('\n').map((line) => {
        return line;
    });

    return (
        <StyledParagraph>
            {lines.map((line, index) => {
                if (index === lines.length - 1) {
                    return <Fragment key={index}>{line}</Fragment>;
                }

                return (
                    <Fragment key={index}>
                        {line}
                        <br />
                    </Fragment>
                );
            })}
        </StyledParagraph>
    );
};
