import { useNavigate, useParams } from '@tanstack/react-router';

import { IconButton } from '../../../cdl/IconButton/IconButton';
import { LogoHeader } from '../../../common/headers/LogoHeader';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { IconX } from '../../../common/icons/cdl/X';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { createOverlay } from '../../../common/Overlay/Overlay';
import { Box } from '../../../common/ui/Box';
import { useOrderView } from '../hooks/useOrderView';

import { OrderEditFormikWrapper } from './OrderEditFormikWrapper';
import { AdvancedError } from '../../../common/Error/AdvancedError';

const PageWithHeader = ({ children }) => {
    const { id } = useParams({ from: '/_app/fuel/order/$id_/edit' });
    const navigate = useNavigate({ from: '/fuel/order/$id/edit' });

    const onCancelClick = () => {
        navigate({ to: '/fuel/order/$id', params: { id } });
    };

    return (
        <Box minHeight="100%" backgroundColor="background.subtle">
            <LogoHeader
                title={translate('order.edit.fuel.headline')}
                rightElement={<IconButton Icon={IconX} onClick={onCancelClick} />}
            />
            {children}
        </Box>
    );
};

const FuelOrderEdit = () => {
    const { id } = useParams({ from: '/_app/fuel/order/$id_/edit' });
    const orderQuery = useOrderView(id);

    const documentTitle = orderQuery.data
        ? translate('page.fuelOrderEdit', {
              vesselName: orderQuery.data.vessel.name,
              portName: orderQuery.data.port.name,
          })
        : null;

    useDocumentTitle(documentTitle);

    if (orderQuery.isError) {
        return (
            <PageWithHeader>
                <AdvancedError />
            </PageWithHeader>
        );
    }

    if (orderQuery.isPending) {
        return (
            <PageWithHeader>
                <LoadingIndicator variant="page" />
            </PageWithHeader>
        );
    }

    return (
        <PageWithHeader>
            <OrderEditFormikWrapper data={orderQuery.data} />
        </PageWithHeader>
    );
};

export const FuelOrderEditFlow = () => {
    return createOverlay(<FuelOrderEdit />);
};
