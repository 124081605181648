import { useLocation, useRouter } from '@tanstack/react-router';

import { getRole } from '../../common/hooks/useRole';
import { useStats } from '../../dashboard/common/hooks/useStats';

export const useRedirectAfterLogin = (showWelcomePopup: boolean = false): (() => Promise<void>) => {
    const statsQuery = useStats(false);

    const router = useRouter();
    const location = useLocation();

    const navigateToDestination = (destination: string) => {
        if (location.search.redirect) {
            router.history.push(location.search.redirect, {
                showWelcomePopup,
            });
        } else {
            router.navigate({ to: destination, replace: true, state: { showWelcomePopup } });
        }
    };

    return async () => {
        const role = getRole();
        const context = role?.context;
        const destination = context === 'FUEL' ? '/fuel/dashboard' : '/dashboard';

        if (context) {
            try {
                await statsQuery.prefetch(context);
            } catch {
                navigateToDestination(destination);
            }
        }

        navigateToDestination(destination);
    };
};
