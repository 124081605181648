import { linkOptions, useLocation, useNavigate, useSearch } from '@tanstack/react-router';

import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { Tabs } from '../../../cdl/Tabs/Tabs';
import { AdvancedError } from '../../../common/Error/AdvancedError';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate as t } from '../../../common/helpers/translate.helper';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { IconCheck } from '../../../common/icons/cdl/Check';
import { IconList } from '../../../common/icons/cdl/List';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { OfferStatusTag } from '../../../common/OfferStatusTag/OfferStatusTag';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { Box } from '../../../common/ui/Box';
import { OfferState } from '../../../offer/model/OfferState';
import { Port } from '../../../port/Port';
import { UnreadIndicator } from '../../common/UnreadIndicator';
import { OrderModel } from '../../model/OrderModel';
import { useOrderPagination } from '../../useOrderPagination';
import { OrderActions } from '../actions/OrderActions';
import { OrderTabEnum, orderTabOfferStateFilter, orderTabStateFilter } from '../orderTabsHelper';
import { useOrderPaginationTotals } from '../useOrderPaginationTotals';
import { useOrderQuickFilterTotals } from '../useOrderQuickFilterTotals';

import { ApprovalStateTag } from './ApprovalStateTag';
import { CombinedOfferApprovalStateTag } from './CombinedOfferApprovalStateTag';
import { OfferCount } from './OfferCount';
import { QuickFilter } from './quickfilter/QuickFilter';
import { mapFilterToApprovalState, mapFilterToOfferState } from './quickfilter/quickFilterMappingHelper';
import { QuickFilterValueEnum } from './quickfilter/QuickFilterValueTypes';
import { useIsApprovalAllowed } from './quickfilter/useIsApprovalAllowed';

export const LubesOrderOverviewTable = () => {
    const role = useRole();
    const { context } = useProductContext();
    const location = useLocation();

    const navigate = useNavigate({ from: '/orders' });
    const search = useSearch({ from: '/_app/_lubes/orders' });

    const handleCustomerIds = (customerIds: string[]) => {
        if (customerIds.length) {
            return customerIds;
        }

        if (role.isAdmin()) {
            return undefined;
        }

        return role.getCompaniesWithType(context).map((it) => it.id);
    };

    const ordersTotalsQuery = useOrderPaginationTotals({
        searchQuery: search.searchQuery,
        customerIds: handleCustomerIds(search.customerIds),
        supplierIds: search.supplierIds,
        types: ProductContext.LUBES,
    });
    const ordersTotals = ordersTotalsQuery.data || {};

    const ordersQuickFilterTotalsQuery = useOrderQuickFilterTotals({
        searchQuery: search.searchQuery,
        customerIds: handleCustomerIds(search.customerIds),
        supplierIds: search.supplierIds,
        types: ProductContext.LUBES,
        tab: search.tab,
    });

    const quickFilterTotals = ordersQuickFilterTotalsQuery.data;

    const paginationQuery = useOrderPagination({
        page: search.page,
        searchQuery: search.searchQuery,
        customerIds: handleCustomerIds(search.customerIds),
        supplierIds: search.supplierIds,
        types: [ProductContext.LUBES],
        offerStates:
            mapFilterToOfferState(search.quickFilter) ??
            orderTabOfferStateFilter[search.tab as keyof typeof orderTabOfferStateFilter],
        states: orderTabStateFilter[search.tab as keyof typeof orderTabStateFilter],
        approvalRequestStates: mapFilterToApprovalState(search.quickFilter) || undefined,
        sortField: search.sortValue?.sortField,
        sortDirection: search.sortValue?.sortDirection,
    });

    const showApprovalFunctionality = useIsApprovalAllowed();

    const onFilterChange = (filter: string) => {
        navigate({
            search: (previousSearch) => ({
                ...previousSearch,
                quickFilter: filter,
                page: 0,
            }),
        });
    };

    const onTabChange = (tab: keyof typeof orderTabOfferStateFilter) => {
        navigate({
            search: (previousSearch) => ({
                ...previousSearch,
                quickFilter: QuickFilterValueEnum.ALL,
                tab,
                page: 0,
            }),
        });
    };

    if (paginationQuery.isPending) {
        return (
            <div>
                <Tabs selectedContext={search.tab} onSelect={onTabChange} totals={ordersTotals} />
                <LoadingIndicator />
            </div>
        );
    }

    if (paginationQuery.isError) {
        return (
            <div>
                <Tabs selectedContext={search.tab} onSelect={onTabChange} totals={ordersTotals} />
                <AdvancedError />
            </div>
        );
    }

    if (!paginationQuery.data.content.length) {
        return (
            <Box display="grid" gap={4}>
                <Tabs selectedContext={search.tab} onSelect={onTabChange} totals={ordersTotals} />
                <QuickFilter
                    tab={search.tab}
                    onChange={onFilterChange}
                    totals={quickFilterTotals}
                    approvalEnabled={showApprovalFunctionality}
                />
                <TableEmptyState Icon={IconList} text={t('order.emptylist')} />
            </Box>
        );
    }

    const buildRowLink = (order: OrderModel) => {
        if (order.isClosed()) {
            return linkOptions({
                to: '/offer/$id',
                params: {
                    id: order.chosenOfferId!,
                },
            });
        } else if (order.isDraft() && !role.isAdmin() && role.hasWriteRights(order.customerId)) {
            return linkOptions({
                to: '/orders/draft/$id',
                params: {
                    id: order.id,
                },
                state: {
                    originLocation: location,
                },
            });
        } else {
            return linkOptions({
                to: '/order/$id',
                params: {
                    id: order.id,
                },
            });
        }
    };

    return (
        <Box display="grid" gap={4}>
            <Tabs selectedContext={search.tab} onSelect={onTabChange} totals={ordersTotals} />
            <QuickFilter
                tab={search.tab}
                onChange={onFilterChange}
                totals={quickFilterTotals}
                approvalEnabled={showApprovalFunctionality}
            />

            <div>
                <TableBuilder
                    data={paginationQuery.data.content}
                    isLoading={paginationQuery.isPlaceholderData && paginationQuery.isFetching}
                    rowLink={(order) => buildRowLink(order)}
                    grow={false}
                >
                    {!role.isAdmin() ? (
                        <TableBuilderColumn<OrderModel> header="" width="2%" hideOnLoading>
                            {(order) => <Box width="8px">{!order.read ? <UnreadIndicator /> : null}</Box>}
                        </TableBuilderColumn>
                    ) : null}

                    <TableBuilderColumn<OrderModel> header={t('order.buyerreferenceshort')} width="15%" truncate>
                        {(order) => order.buyerReference || '-'}
                    </TableBuilderColumn>

                    {!role.isOneCompanyUser('LUBES') && (
                        <TableBuilderColumn<OrderModel>
                            header={role.isCustomer() ? t('order.company') : t('order.customer')}
                            width="10%"
                            truncate
                        >
                            {(order) => formatCompanyName({ company: order.customer }) || null}
                        </TableBuilderColumn>
                    )}

                    <TableBuilderColumn<OrderModel> header={t('order.vessel')} width="10%" truncate>
                        {(order) => formatVessel({ vessel: order.vessel, short: true })}
                    </TableBuilderColumn>

                    <TableBuilderColumn<OrderModel> header={t('order.port')} width="15%">
                        {(order) => {
                            if (!order.port) {
                                return null;
                            }
                            return <Port port={order.port} vesselId={order.vesselId} locode={false} showTooltip />;
                        }}
                    </TableBuilderColumn>

                    <TableBuilderColumn<OrderModel> header={t('order.dateDeliveryShort')} width="10%">
                        {(order) => {
                            if (!order.dateDelivery) {
                                return null;
                            }
                            return formatDate({ date: order.dateDelivery, timeZone: 'UTC' });
                        }}
                    </TableBuilderColumn>

                    {[OrderTabEnum.ORDERED, OrderTabEnum.DELIVERED, OrderTabEnum.CANCELED].includes(search.tab) ? (
                        <TableBuilderColumn<OrderModel> header={t('order.supplier')} width="10%" truncate>
                            {(order) => formatCompanyName({ company: order.supplier }) || null}
                        </TableBuilderColumn>
                    ) : null}

                    {[OrderTabEnum.ENQUIRED].includes(search.tab) ? (
                        <TableBuilderColumn<OrderModel> header={t('order.status')} width="10%">
                            {(order) => (
                                <CombinedOfferApprovalStateTag
                                    offerState={order.offerState}
                                    approvalRequestState={order.approvalRequestState}
                                />
                            )}
                        </TableBuilderColumn>
                    ) : null}

                    {[OrderTabEnum.ORDERED, OrderTabEnum.DELIVERED].includes(search.tab) &&
                    showApprovalFunctionality ? (
                        <TableBuilderColumn<OrderModel> header={t('order.approvalState')} width="10%">
                            {(order) => {
                                if (order.approvalRequestState) {
                                    return <ApprovalStateTag state={order.approvalRequestState} />;
                                }
                                return null;
                            }}
                        </TableBuilderColumn>
                    ) : null}

                    {[OrderTabEnum.ORDERED, OrderTabEnum.CANCELED].includes(search.tab) ? (
                        <TableBuilderColumn<OrderModel> header={t('order.status')} width="10%">
                            {(order) => {
                                if (
                                    !order.offerState ||
                                    [OfferState.DRAFT, OfferState.DELIVERED].includes(order.offerState)
                                ) {
                                    return null;
                                }
                                return <OfferStatusTag state={order.offerState} variant="customer" />;
                            }}
                        </TableBuilderColumn>
                    ) : null}

                    {[OrderTabEnum.ENQUIRED].includes(search.tab) ? (
                        <TableBuilderColumn<OrderModel> header={t('order.offercount')} width="5%">
                            {(order) => {
                                return (
                                    <OfferCount
                                        offerCount={order.offerCount}
                                        totalOffers={order.receiverSupplierIds?.length || 0}
                                        includesSpot={order.spot}
                                    />
                                );
                            }}
                        </TableBuilderColumn>
                    ) : null}

                    {[OrderTabEnum.DRAFTED].includes(search.tab) ? (
                        <TableBuilderColumn<OrderModel> header={t('order.created')} width="10%">
                            {(order) => formatDate({ date: order.dateCreated })}
                        </TableBuilderColumn>
                    ) : null}

                    {role.isAdmin() && search.tab !== OrderTabEnum.DRAFTED ? (
                        <TableBuilderColumn<OrderModel> header={t('order.detail.spot')} width="5%">
                            {(order) => {
                                if (order.spot) {
                                    return <IconCheck height={16} width={16} />;
                                }
                                return null;
                            }}
                        </TableBuilderColumn>
                    ) : null}

                    {!(role.isAdmin() && search.tab !== OrderTabEnum.DRAFTED) ? (
                        <TableBuilderColumn<OrderModel> header="" hideOnLoading interactive width="5%">
                            {(order) => (
                                <Box display="flex" justifyContent="flex-end">
                                    <OrderActions order={order} />
                                </Box>
                            )}
                        </TableBuilderColumn>
                    ) : null}
                </TableBuilder>

                <CenteredPagination
                    pageTotal={paginationQuery.pageTotal}
                    currentPage={search.page}
                    onPageChange={(page) => {
                        navigate({
                            search: (previousSearch) => ({
                                ...previousSearch,
                                page,
                            }),
                        });
                    }}
                />
            </div>
        </Box>
    );
};
