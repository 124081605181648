import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
    createOrderConfirmationDocument,
    CreateOrderConfirmationDocumentRequestMessage,
} from '../common/api/clients/bass-net-api';

export const useBassNetOrderConfirmationDocument = () => {
    return useMutation<void, AxiosError<{ message: string }>, CreateOrderConfirmationDocumentRequestMessage>({
        mutationFn: createOrderConfirmationDocument,
    });
};
