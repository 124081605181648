import styled, { css, keyframes } from 'styled-components';

import { get } from '../../../common/ui/get';

const expand = keyframes`
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1);
    }
`;

const activeStyles = css`
    color: ${get('colors.accent.foreground')};
    position: relative;

    &:hover {
        background-color: ${get('colors.accent.background')};
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -8px;
        left: 8px;
        height: 2px;
        width: calc(100% - 16px);
        background-color: ${get('colors.accent.emphasis')};
        border-radius: 10px;
        transform-origin: center;
        transform: translateX(-50%) scaleX(0);
        animation: ${expand} 150ms ease forwards;
    }
`;

interface TabProps {
    active?: boolean;
}

export const Tab = styled.button<TabProps>`
    display: flex;
    align-items: baseline;
    gap: ${get('space.2')}px;
    border: none;
    border-radius: ${get('radii.2')}px;
    font-weight: ${get('fontWeights.medium')};
    font-size: ${get('fontSizes.2')}px;
    line-height: 24px;
    padding: 0 ${get('space.4')}px;
    color: ${get('colors.foreground.default')};

    &:hover {
        background-color: ${get('colors.background.inset')};
    }

    ${(props) => (props.active ? activeStyles : null)}
`;
