import styled from 'styled-components';
import { variant } from 'styled-system';

import { useCharacterLimit } from '../../offer/ApprovalRequest/useCharacterLimit';
import { get } from '../ui/get';

interface CircleWrapProps {
    size: number;
}

const CircleWrap = styled.div<CircleWrapProps>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    background: ${get('colors.accent.background')};
    border-radius: 50%;
`;

const MagicCircle = styled.div``;

interface MaskProps {
    size: number;
}

const Mask = styled.div<MaskProps>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    position: absolute;
    border-radius: 50%;
    clip: ${(props) => `rect(0px, ${props.size}px, ${props.size}px, ${props.size / 2}px)`};
`;

interface FullMaskProps {
    degrees: number;
}

const FullMask = styled(Mask)<FullMaskProps>`
    transform: ${(props) => `rotate(${props.degrees}deg)`};
    transition: transform 0.3s;
`;

enum FillType {
    WARNING = 'WARNING',
    DANGER = 'DANGER',
    NEUTRAL = 'NEUTRAL',
}

interface FillProps {
    size: number;
    degrees: number;
    type: FillType;
}

const Fill = styled.div<FillProps>`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    position: absolute;
    border-radius: 50%;
    clip: ${(props) => `rect(0px, ${props.size / 2}px, ${props.size}px, 0px)`};
    transform: ${(props) => `rotate(${props.degrees}deg)`};
    transition: transform 0.3s;

    ${variant({
        prop: 'type',
        variants: {
            WARNING: {
                backgroundColor: 'attention.emphasis',
            },
            DANGER: {
                backgroundColor: 'negative.emphasis',
            },
            NEUTRAL: {
                backgroundColor: 'accent.emphasis',
            },
        },
    })};
`;

interface InsideCircleProps {
    circleSize: number;
    size: number;
    fontSize: number;
}

const InsideCircle = styled.div<InsideCircleProps>`
    width: ${(props) => props.circleSize}px;
    height: ${(props) => props.circleSize}px;
    border-radius: 50%;
    background: #fff;
    line-height: ${(props) => props.circleSize}px;
    text-align: center;
    margin-top: ${(props) => (props.size - props.circleSize) / 2}px;
    margin-left: ${(props) => (props.size - props.circleSize) / 2}px;
    position: absolute;
    z-index: 100;
    font-size: ${(props) => props.fontSize}px;
    font-weight: 400;
`;

const selectFillType = (limitReachedPercent: number) => {
    if (limitReachedPercent >= 1.0) {
        return FillType.DANGER;
    }

    if (limitReachedPercent >= 0.9) {
        return FillType.WARNING;
    }

    return FillType.NEUTRAL;
};

interface CharacterCountProps {
    count: number;
    limit: number;
    size?: number;
    fontSize: number;
}

export const CharacterCount = ({ limit, count, size = 36, fontSize = 12 }: CharacterCountProps) => {
    const { remainingCharacters } = useCharacterLimit({
        characterLength: count,
        characterLimit: limit,
    });

    const characterLimitReachedPercent = count / limit;

    let degrees = 360 * (characterLimitReachedPercent / 2);
    if (degrees >= 180) {
        degrees = 180;
    }

    const fillType = selectFillType(characterLimitReachedPercent);

    const circleSize = size * 0.85;

    return (
        <CircleWrap size={size}>
            <MagicCircle>
                <FullMask size={size} degrees={degrees}>
                    <Fill size={size} degrees={degrees} type={fillType} />
                </FullMask>

                <Mask size={size}>
                    <Fill size={size} degrees={degrees} type={fillType} />
                </Mask>

                <InsideCircle size={size} circleSize={circleSize} fontSize={fontSize}>
                    {remainingCharacters}
                </InsideCircle>
            </MagicCircle>
        </CircleWrap>
    );
};
