import { DeclinedApprovalRequestMessage } from '../../../../types/ApprovalRequestMessage';
import { Text } from '../../../cdl/Text/Text';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { formatMoney } from '../../../common/helpers/formatMoney.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { Paragraph } from '../../../common/ui/Paragraph';
import { ApprovalResponse } from '../ApprovalResponse';
import { MetaData } from '../MetaData';

import { ApprovalRequestContentWrapper } from './ApprovalRequestContentWrapper';

interface DeclinedApprovalRequestContentProps {
    approvalRequest: DeclinedApprovalRequestMessage;
}

export const DeclinedApprovalRequestContent = (props: DeclinedApprovalRequestContentProps) => {
    const requestText = props.approvalRequest.requestMessage
        ? translate('approvalRequest.dropdown.createdHeadlineComment', {
              requestMessage: props.approvalRequest.requestMessage,
          })
        : translate('approvalRequest.dropdown.createdHeadlineNoComment');

    return (
        <ApprovalRequestContentWrapper>
            {props.approvalRequest.total ? (
                <Text size={14} weight="bold">
                    {translate('approvalRequest.dropdown.total', {
                        value: formatMoney({
                            value: props.approvalRequest.total.value,
                            currency: props.approvalRequest.total.currency,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 3,
                        }),
                    })}
                </Text>
            ) : null}
            <Paragraph>{requestText}</Paragraph>
            <MetaData>
                {translate('approvalRequest.dropdown.changeInformation', {
                    date: formatDateTime({
                        date: props.approvalRequest.dateCreated,
                    }),
                    author: `${props.approvalRequest.createdByUser.firstname} ${props.approvalRequest.createdByUser.lastname}`,
                })}
            </MetaData>
            <ApprovalResponse
                response={
                    props.approvalRequest.responseMessage
                        ? translate('approvalRequest.dropdown.declined.responseComment', {
                              responseMessage: props.approvalRequest.responseMessage,
                          })
                        : translate('approvalRequest.dropdown.declined.responseNoComment')
                }
                metaData={translate('approvalRequest.dropdown.changeInformation', {
                    date: formatDateTime({
                        date: props.approvalRequest.dateDeclined,
                    }),
                    author: props.approvalRequest.controllerEmailAddress,
                })}
            />
        </ApprovalRequestContentWrapper>
    );
};
