import { useParams } from '@tanstack/react-router';

import { UserType } from '../../../../types/UserType';
import { Button } from '../../../cdl/Button/Button';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { Text } from '../../../cdl/Text/Text';
import { AdvancedError } from '../../../common/Error/AdvancedError';
import { HeaderWithLine } from '../../../common/HeaderWithLine/HeaderWithLine';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useRole } from '../../../common/hooks/useRole';
import { IconPlus } from '../../../common/icons/cdl/Plus';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../../common/Page/Page';
import { Box } from '../../../common/ui/Box';

import { ApiKeysTable } from './components/ApiKeysTable';
import { CreateApiKeyButton } from './components/CreateApiKeyButton';
import { CreateCustomerCompany } from './components/CreateCustomerCompany';
import { CustomerGroupDataSheet } from './components/CustomerGroupDataSheet';
import { CustomerHealthButton } from './components/CustomerHealthButton';
import { CustomersTable } from './components/CustomersTable';
import { DownloadLubesReportButton } from './components/DownloadLubesReportButton';
import { EditCustomerGroupForm } from './components/EditCustomerGroupForm';
import { useCustomerGroupDetails } from './hooks/useCustomerGroupDetails';

export const CustomerGroupDetailPage = () => {
    const { id: groupId } = useParams({ from: '/_app/customer-group/$id' });

    const role = useRole();
    const { push, pop } = useDrawer();

    const customerGroupDetailsQuery = useCustomerGroupDetails(groupId);

    useDocumentTitle(customerGroupDetailsQuery.data?.name);

    if (customerGroupDetailsQuery.isPending) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (customerGroupDetailsQuery.isError) {
        return (
            <Page>
                <AdvancedError />
            </Page>
        );
    }

    const customerGroup = customerGroupDetailsQuery.data;

    const handleCustomerGroupEditClick = () => {
        push({
            content: <EditCustomerGroupForm onDismiss={() => pop()} initialValues={customerGroup} />,
        });
    };

    const handleCompanyCreateClick = () => {
        push({
            content: (
                <CreateCustomerCompany
                    onSuccess={() => pop()}
                    groupId={groupId}
                    isTestGroup={customerGroup.testCompany}
                />
            ),
        });
    };

    return (
        <Page>
            <Box display="flex" flexDirection="column" gap={9}>
                <Box display="flex" flexDirection="column" gap={5}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Text variant="headline" as="h1">
                            {customerGroup.name}
                        </Text>
                        <Box display="flex" gap={4}>
                            {role.isAdmin() ? (
                                <CustomerHealthButton
                                    customerGroupId={groupId}
                                    customerGroupName={customerGroup.name}
                                />
                            ) : null}
                            {role.isAdmin() ? <DownloadLubesReportButton customerGroupId={groupId} /> : null}
                            {role.hasAdminRights() ? (
                                <Button emphasis="high" onClick={handleCustomerGroupEditClick}>
                                    {translate('customergroup.edit')}
                                </Button>
                            ) : null}
                        </Box>
                    </Box>
                </Box>

                <Box display="flex" flexDirection="column" gap={5}>
                    <HeaderWithLine>
                        <Text variant="title" as="h2">
                            {translate('customers.detailsSectionHeadline')}
                        </Text>
                    </HeaderWithLine>
                    <CustomerGroupDataSheet customerGroup={customerGroup} />
                </Box>
                <Box display="flex" flexDirection="column" gap={5}>
                    <HeaderWithLine>
                        <Text variant="title" as="h2">
                            {translate('customers.subheadline')}
                        </Text>
                        {role.hasAdminRights() ? (
                            <Button
                                leadingVisual={<IconPlus width={16} height={16} />}
                                onClick={handleCompanyCreateClick}
                            >
                                {translate('customers.create')}
                            </Button>
                        ) : null}
                    </HeaderWithLine>
                    <CustomersTable groupId={groupId} />
                </Box>

                {(role.isCustomer() && role.hasAdminRights()) || role.isAdmin() ? (
                    <Box display="flex" flexDirection="column" gap={5}>
                        <HeaderWithLine description={translate('apikey.description')}>
                            <Text variant="title" as="h2">
                                {translate('apikey.title')}
                            </Text>

                            <CreateApiKeyButton groupId={groupId} userType={UserType.CUSTOMER} />
                        </HeaderWithLine>
                        <ApiKeysTable groupId={groupId} userType={UserType.CUSTOMER} />
                    </Box>
                ) : null}
            </Box>
        </Page>
    );
};
