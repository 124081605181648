import styled from 'styled-components';

import { get } from './get';

/** @deprecated use CDL Text instead */

export const Paragraph = styled.p`
    margin: 8px 0 0 0;
    font-weight: 400;
    color: ${get('colors.foreground.muted')};
    line-height: 1.3;
    font-size: 14px;

    &:first-of-type {
        margin-top: 0;
    }
`;
