import { useField } from 'formik';

import { ProductMessage } from '../../../../../types/ProductMessage';
import { VesselTankMessage } from '../../../../../types/VesselTankMessage';
import { Text } from '../../../../cdl/Text/Text';
import { useVesselTanks } from '../../../../order/create/hooks/useVesselTanks';
import { FormikProductSelect } from '../../../form-elements/formik/FormikProductSelect';
import { translate } from '../../../helpers/translate.helper';
import { useLubesProductStringFormat } from '../../../hooks/useLubesProductStringFormat';
import { useRole } from '../../../hooks/useRole';
import { Box } from '../../../ui/Box';

interface ProductNameDisplayProps {
    name: string;
    vesselIdName: string;
    onSelect: (product: ProductMessage) => void;
    editable: boolean;
}

export const ProductNameDisplay = (props: ProductNameDisplayProps) => {
    const [itemField] = useField(props.name);
    const [vesselIdField] = useField(props.vesselIdName);

    const item = itemField.value;
    const vesselId = vesselIdField.value;

    const formatProductString = useLubesProductStringFormat();
    const role = useRole();

    const vesselTanksQuery = useVesselTanks({
        vesselId,
        enabled: !!(role?.isCustomer() && props.editable && vesselId),
    });

    const defaultProductsFromTanks = vesselTanksQuery.data?.vesselTanks
        .map((tank: VesselTankMessage) => tank.defaultProduct)
        .filter(Boolean);

    const uniqueDefaultProducts = defaultProductsFromTanks?.length
        ? defaultProductsFromTanks.reduce((acc: ProductMessage[], current: ProductMessage) => {
              if (!acc.map((product) => product.id).includes(current.id)) {
                  acc.push(current);
              }
              return acc;
          }, [])
        : [];

    if (!props.editable) {
        return (
            <Box display="flex" flexDirection="column" gap={4}>
                <Text variant="body">{formatProductString(item.product)}</Text>

                {item.baseProduct && item.baseProductId !== item.productId ? (
                    <Text variant="subtitle2" color="foreground.subtle">
                        {translate('offer.edit.lubes.requestedProduct', {
                            product: formatProductString(item.baseProduct),
                        })}
                    </Text>
                ) : null}
            </Box>
        );
    }

    return (
        <Box display="flex" flexDirection="column" gap={4}>
            <FormikProductSelect
                name={`${props.name}.productId`}
                disabled={item.deleted}
                onSelect={props.onSelect}
                defaultProducts={uniqueDefaultProducts.length ? uniqueDefaultProducts : undefined}
            />

            {item.baseProduct && item.baseProductId !== item.productId ? (
                <Text
                    marginLeft="12px"
                    variant="subtitle2"
                    color={item.deleted ? 'foreground.disabled' : 'foreground.subtle'}
                >
                    {translate('offer.edit.lubes.requestedProduct', {
                        product: formatProductString(item.baseProduct),
                    })}
                </Text>
            ) : null}
        </Box>
    );
};
