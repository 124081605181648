import { ExpiredApprovalRequestMessage } from '../../../../types/ApprovalRequestMessage';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { Paragraph } from '../../../common/ui/Paragraph';
import { MetaData } from '../MetaData';

import { ApprovalRequestContentWrapper } from './ApprovalRequestContentWrapper';

interface ExpiredApprovalRequestContentProps {
    approvalRequest: ExpiredApprovalRequestMessage;
}

export const ExpiredApprovalRequestContent = (props: ExpiredApprovalRequestContentProps) => {
    if (props.approvalRequest.updatedByUser) {
        return (
            <ApprovalRequestContentWrapper>
                <Paragraph>{translate('approvalRequest.dropdown.expiredUser.headline')}</Paragraph>
                <MetaData>
                    {translate('approvalRequest.dropdown.changeInformation', {
                        date: formatDateTime({
                            date: props.approvalRequest.dateExpired,
                        }),
                        author: `${props.approvalRequest.updatedByUser.firstname} ${props.approvalRequest.updatedByUser.lastname}`,
                    })}
                </MetaData>
            </ApprovalRequestContentWrapper>
        );
    }

    return (
        <ApprovalRequestContentWrapper>
            <Paragraph>{translate('approvalRequest.dropdown.expiredSystem.headline')}</Paragraph>
            <MetaData>
                {translate('approvalRequest.dropdown.expiredSystem.subHeadline', {
                    date: formatDateTime({
                        date: props.approvalRequest.dateExpired,
                    }),
                })}
            </MetaData>
        </ApprovalRequestContentWrapper>
    );
};
