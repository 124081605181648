import styled from 'styled-components';

import { IconButton, IconButtonProps } from '../../IconButton/IconButton';

interface ScrollButtonProps extends IconButtonProps {
    visible: boolean;
    hasSecondaryLabel?: boolean;
}

export const RadioCardGroupScrollButton = styled(IconButton)<ScrollButtonProps>`
    position: absolute;
    top: ${(props) => (props.hasSecondaryLabel ? 16 : 4)}px;
    z-index: 1;

    pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
    transition: opacity 200ms ease-in-out;
    opacity: ${(props) => (props.visible ? 1 : 0)};
`;
