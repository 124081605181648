import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';

import { ToastsProvider } from '../cdl/Toast/ToastsProvider';
import { AdvancedError } from '../common/Error/AdvancedError';
import { ThemeProvider } from '../common/ThemeProvider';
import { Toolbox } from '../dev-tools/components/Toolbox';

const Root = () => {
    return (
        <ThemeProvider>
            <Outlet />
            <Toolbox />
            <ToastsProvider />
        </ThemeProvider>
    );
};

interface AppRouteContext {
    queryClient: QueryClient;
}

export const Route = createRootRouteWithContext<AppRouteContext>()({
    component: Root,
    errorComponent: ({ error, reset }) => <AdvancedError error={error} resetError={reset} />,
});
