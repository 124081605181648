import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../helpers/translate.helper';
import { Characters } from '../../helpers/unicode.helper';
import { useEnums } from '../../hooks/useEnums';
import { useLubesProductStringFormat } from '../../hooks/useLubesProductStringFormat';
import { ItemModel } from '../../models/ItemModel';
import { Box } from '../../ui/Box';

interface ProductDisplayProps {
    item: ItemModel;
}

export const ProductDisplay = ({ item }: ProductDisplayProps) => {
    const { getHumanReadableValue } = useEnums();
    const formatProductString = useLubesProductStringFormat();

    if (!item.isTranslationMissing() && !item.productId && !item.baseProductId) {
        return <>-</>;
    }

    return (
        <Box display="grid">
            {!item.isTranslationMissing() ? (
                <Text color={item.changes.productIdChanged ? 'attention.foreground' : 'inherit'}>
                    {formatProductString(item.product)}
                </Text>
            ) : (
                <Text color={item.deleted ? 'inherit' : 'attention.foreground'}>
                    {translate('global.translationmissing')}
                </Text>
            )}

            {item.baseProduct && item.baseProductId !== item.productId ? (
                <>
                    <Text variant="extraSmall" color={item.deleted ? 'foreground.disabled' : 'foreground.subtle'}>
                        {translate('order.requestedproduct')}
                        {Characters.NO_BREAK_SPACE}
                        {formatProductString(item.baseProduct)}
                    </Text>

                    {!item.productId && item.baseProduct ? (
                        <Text variant="extraSmall" color={item.deleted ? 'foreground.disabled' : 'foreground.subtle'}>
                            {getHumanReadableValue('productGroup', item.baseProduct.group)}
                        </Text>
                    ) : null}
                </>
            ) : null}

            {item.product?.group ? (
                <Text variant="extraSmall" color={item.deleted ? 'foreground.disabled' : 'foreground.subtle'}>
                    {getHumanReadableValue('productGroup', item.product!.group)}
                </Text>
            ) : null}
        </Box>
    );
};
