import { ConfirmDeliveryPopup } from '../common/components/ConfirmDeliveryPopup';
import { LubesReadOnlyProductTable } from '../../../common/LubesReadOnlyProductTable/LubesReadOnlyProductTable';

export const LubesConfirmDeliveryPopup = ({ onDismiss, offer, onConfirm, isSubmitting, isOpen }) => {
    return (
        <ConfirmDeliveryPopup
            onDismiss={onDismiss}
            isOpen={isOpen}
            offer={offer}
            onConfirm={onConfirm}
            isSubmitting={isSubmitting}
        >
            <LubesReadOnlyProductTable
                items={offer.items}
                sampleKits={offer.samplekits}
                surcharges={offer.surcharges}
                totals={{ volume: offer.volume, price: offer.ppl, total: offer.total }}
            />
        </ConfirmDeliveryPopup>
    );
};
