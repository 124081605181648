import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { TextButton } from '../buttons/TextButton';
import { translate } from '../helpers/translate.helper';
import { get } from '../ui/get';

const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.background.inset};
    padding: ${get('space.5')}px ${get('space.6')}px;
    display: flex;
    gap: ${get('space.4')}px;
    flex-direction: column;
    align-items: flex-start;
`;

interface TextWrapperProps {
    shouldTruncate: boolean;
}

const TextWrapper = styled.div<TextWrapperProps>`
    width: 100%;
    overflow: hidden;
    text-overflow: ${({ shouldTruncate }) => (shouldTruncate ? 'ellipsis' : 'clip')};
    white-space: ${({ shouldTruncate }) => (shouldTruncate ? 'nowrap' : 'pre-line')};
`;

interface OrderCreationCommentNewProps {
    text: string;
}

export const CreationComment = ({ text }: OrderCreationCommentNewProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);

    const textWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (textWrapperRef.current) {
            setIsTruncated(textWrapperRef.current.scrollWidth > textWrapperRef.current.clientWidth);
        }
    }, []);

    return (
        <Container>
            <Text variant="subtitle1">{translate('creationComment.headline')}</Text>
            <TextWrapper ref={textWrapperRef} shouldTruncate={!isExpanded}>
                <Text variant="small" color="foreground.muted">
                    {text}
                </Text>
            </TextWrapper>
            {isTruncated ? (
                <TextButton type="button" onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? translate('creationComment.showLess') : translate('creationComment.showMore')}
                </TextButton>
            ) : null}
        </Container>
    );
};
