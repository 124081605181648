import { StatePlaceholder } from '../../../cdl/StatePlaceholder/StatePlaceholder';
import { translate } from '../../../common/helpers/translate.helper';
import { IconFilter } from '../../../common/icons/cdl/Filter';
import { Flex } from '../../../common/ui/Flex';

export const AssignmentsEmptyState = () => {
    return (
        <Flex justifyContent="center" paddingTop="124px">
            <StatePlaceholder
                title={translate('assignments.noAssignmnents')}
                description={translate('assignments.tryDifferentFilter')}
                Icon={IconFilter}
            />
        </Flex>
    );
};
