import { useParams } from '@tanstack/react-router';
import * as uuid from 'uuid';

import { Text } from '../../../cdl/Text/Text';
import { AdvancedError } from '../../../common/Error/AdvancedError';
import { HeaderWithLine } from '../../../common/HeaderWithLine/HeaderWithLine';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useRole } from '../../../common/hooks/useRole';
import { useSchedules } from '../../../common/hooks/useSchedules';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { LubesReadOnlyProductTable } from '../../../common/LubesReadOnlyProductTable/LubesReadOnlyProductTable';
import { Page } from '../../../common/Page/Page';
import { Box } from '../../../common/ui/Box';
import { CompareOverlayFlow } from '../../compare/overlay/CompareOverlayFlow';
import { OrderVesselInformation } from '../../create/OrderVesselInformation';
import { OrderState } from '../../model/OrderModel';
import { RequestSuppliersButton } from '../common/RequestSuppliersButton';

import { EnquiredProductsAccordion } from './components/EnquiredProductsAccordion';
import { LubesOfferCompareActionBar } from './components/LubesOfferCompareActionBar';
import { OfferList } from './components/OfferList';
import { OrderDetails } from './components/OrderDetails';
import { OrderStateTag } from './components/OrderStateTag';
import { useLubesOrderDetails } from './hooks/useLubesOrderDetails';

export const LubesOfferComparePage = () => {
    const { id } = useParams({ from: '/_app/_lubes/order/$id' });
    const role = useRole();

    const orderQuery = useLubesOrderDetails(id);

    const order = orderQuery.data;
    const { schedules: schedule, isPending: isLoadingSchedules } = useSchedules(order?.vessel?.imo);

    useDocumentTitle(translate('page.ordershow', { orderNumber: order?.orderNumber ?? '' }));

    if (orderQuery.isPending) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (orderQuery.isError || !order) {
        return (
            <Page>
                <AdvancedError />
            </Page>
        );
    }

    return (
        <Page>
            <Box display="grid" rowGap={6}>
                <HeaderWithLine>
                    <Box display="flex" flexDirection="row" gap={4} alignItems="baseline">
                        <Text variant="headline" as="h1">
                            {translate('order.detail.headline')}
                        </Text>
                        <Box display="flex" flexDirection="row" gap={4} alignItems="center">
                            <Text variant="title" color="foreground.subtle">
                                {order.orderNumber}
                            </Text>

                            <OrderStateTag state={order.state} />
                        </Box>
                    </Box>

                    <LubesOfferCompareActionBar order={order} />
                </HeaderWithLine>

                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={8}>
                    <div>
                        <OrderDetails order={order} />
                    </div>
                    <OrderVesselInformation vesselId={order.vesselId} />
                </Box>

                {order.isDraft() && (order.items.length || order.samplekits.length) ? (
                    <LubesReadOnlyProductTable items={order.items} sampleKits={order.samplekits} />
                ) : null}

                {!order.isDraft() ? (
                    <EnquiredProductsAccordion items={order.items} sampleKits={order.samplekits} />
                ) : null}

                {role.isAdmin() && order.state !== OrderState.DRAFT && !isLoadingSchedules ? (
                    // @ts-ignore
                    <CompareOverlayFlow trackingId={uuid.v4()} order={order} schedules={schedule} />
                ) : null}

                <Box display="grid" rowGap={5} marginTop={6}>
                    <HeaderWithLine>
                        <Text variant="title" as="h2">
                            {translate('order.detail.offers')}
                        </Text>

                        <RequestSuppliersButton order={order} />
                    </HeaderWithLine>

                    <OfferList order={order} />
                </Box>
            </Box>
        </Page>
    );
};
