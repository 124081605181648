import { Button } from '../../cdl/Button/Button';
import { StatePlaceholder } from '../../cdl/StatePlaceholder/StatePlaceholder';
import { translate } from '../helpers/translate.helper';
import { IconAlertTriangle } from '../icons/cdl/AlertTriangle';
import { Box } from '../ui/Box';

const Error = () => {
    return (
        <StatePlaceholder
            title={translate('global.genericError.title')}
            description={translate('global.genericError.description')}
            Icon={IconAlertTriangle}
            action={<Button onClick={() => window.location.reload()}>{translate('errorView.reloadPage')}</Button>}
        />
    );
};

interface GenericErrorProps {
    variant?: 'centered' | 'basic';
}

export const GenericError = ({ variant = 'centered' }: GenericErrorProps) => {
    switch (variant) {
        case 'centered':
            return (
                <Box display="flex" justifyContent="center" paddingTop="64px" paddingBottom="32px">
                    <Error />
                </Box>
            );
        case 'basic':
            return <Error />;
    }
};
