import { useSearch } from '@tanstack/react-router';

import { AdvancedError } from '../../common/Error/AdvancedError';
import { useProductContext } from '../../common/hooks/useProductContext';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { useSupplierStatistics } from '../hooks/useSupplierStatistics';

import { NoStatistics } from './NoStatistics';
import { StatisticsCenteredFrame } from './StatisticsCenteredFrame';
import { SupplierStatisticsContent } from './SupplierStatisticsContent';
import { SupplierStatisticsTabSwitch } from './SupplierStatisticsTabSwitch';

export const SupplierStatistics = () => {
    const { context, isFuel } = useProductContext();
    const search = useSearch({ from: isFuel ? '/_app/fuel/statistics' : '/_app/_lubes/statistics' });

    const supplierStatisticsQuery = useSupplierStatistics({
        from: search.from,
        to: search.to,
        supplierIds: search.supplierIds,
        type: context,
    });

    if (supplierStatisticsQuery.isPending) {
        return (
            <div>
                <SupplierStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <LoadingIndicator />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    if (supplierStatisticsQuery.isError) {
        return (
            <StatisticsCenteredFrame>
                <AdvancedError />
            </StatisticsCenteredFrame>
        );
    }

    if (supplierStatisticsQuery.data?.general.completedCount === 0) {
        return (
            <div>
                <SupplierStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <NoStatistics />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    return (
        <div>
            <SupplierStatisticsTabSwitch />
            <SupplierStatisticsContent statistics={supplierStatisticsQuery.data} />
        </div>
    );
};
