import {
    Menu as ReachMenu,
    MenuButton as ReachMenuButton,
    MenuItem as ReachMenuItem,
    MenuList as ReachMenuList,
} from '@reach/menu-button';
import { Fragment } from 'react';
import styled from 'styled-components';

import { ApprovalRequestMessage } from '../../../../types/ApprovalRequestMessage';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { IconChevronDown } from '../../../common/icons/cdl/ChevronDown';
import { Box } from '../../../common/ui/Box';
import { get } from '../../../common/ui/get';
import { MetaData } from '../MetaData';

import { ApprovalRequestMenuItem } from './ApprovalRequestMenuItem';
import { ApprovalRequestStateTag } from './ApprovalRequestStateTag';

const ClickableArea = styled.div`
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid ${get('colors.border.muted')};
    border-radius: 4px;
    padding: ${get('space.4')}px;
    width: 350px;
    user-select: none;
    cursor: pointer;
    height: ${get('space.7')}px;
    gap: ${get('space.4')}px;
`;

interface MenuButtonProps {
    approvalRequest: ApprovalRequestMessage;
}

const MenuButton = ({ approvalRequest }: MenuButtonProps) => {
    return (
        <ClickableArea>
            <ApprovalRequestStateTag approvalRequest={approvalRequest} showTooltip={false} />
            <MetaData>
                {translate('approvalRequest.dropdown.latestActivity', {
                    date: formatDate({
                        date: approvalRequest.dateUpdated,
                    }),
                })}
            </MetaData>
            <Box marginLeft="auto" color="foreground.subtle">
                <IconChevronDown />
            </Box>
        </ClickableArea>
    );
};

interface ApprovalRequestsDropdownProps {
    approvalRequests: ApprovalRequestMessage[];
}

export const ApprovalRequestsDropdown = ({ approvalRequests }: ApprovalRequestsDropdownProps) => {
    const latestApprovalRequest = approvalRequests[0];

    return (
        <ReachMenu>
            {() => (
                <Fragment>
                    <ReachMenuButton>
                        <MenuButton approvalRequest={latestApprovalRequest} />
                    </ReachMenuButton>
                    <ReachMenuList style={{ position: 'relative', zIndex: 2 }}>
                        {approvalRequests.map((approvalRequest) => (
                            <ReachMenuItem
                                style={{ minWidth: '500px', maxWidth: '600px' }}
                                key={approvalRequest.id}
                                onSelect={() => {}}
                            >
                                <ApprovalRequestMenuItem approvalRequest={approvalRequest} />
                            </ReachMenuItem>
                        ))}
                    </ReachMenuList>
                </Fragment>
            )}
        </ReachMenu>
    );
};
