import { useEffect, useState } from 'react';

import { CategoryState } from '../../../../types/ReachInformationMessage';
import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../../common/helpers/translate.helper';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Section } from '../../../common/Section/Section.tsx';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { usePlattsCredentials } from '../../../company/customer/detail/integrations/hooks/usePlattsCredentials';
import { useFuelSchedulesWithPrices } from '../hooks/useFuelSchedulesWithPrices';
import { FuelSchedulePriceCompare } from '../PriceCompare/FuelSchedulePriceCompare';
import { ScheduleStop } from './ScheduleStop';
import { ScheduleSlider } from '../Slider/ScheduleSlider';
import { ScheduleStopContainer } from '../Slider/ScheduleStopContainer';
import { ScheduleWrapper } from '../Slider/ScheduleWrapper';
import { useCheapestDefaultScope } from '../hooks/useCheapestDefaultScope';
import { AdvancedError } from '../../../common/Error/AdvancedError';

const createFuelPricesMap = (prices) => {
    const pricesMap = {};
    prices?.forEach((priceData) => {
        if (!pricesMap[priceData.portId]) {
            pricesMap[priceData.portId] = [];
        }

        pricesMap[priceData.portId].push(priceData);
    });
    return pricesMap;
};

const createReachInformationMap = (reachInformation) => {
    if (!reachInformation) {
        return {};
    }

    const scheduleIdToReachInformationMap = {};

    reachInformation.forEach((it) => {
        scheduleIdToReachInformationMap[it.scheduleId] = {};

        it.categories.forEach((category) => {
            scheduleIdToReachInformationMap[it.scheduleId][`${category.productGroup}_${category.sulphurContent}`] =
                category;
        });
    });

    return scheduleIdToReachInformationMap;
};

const stopHasCheapestDefault = (stop, pricesMap) => {
    const pricesForPort = pricesMap[stop.port?.id];

    if (!pricesForPort) {
        return false;
    }

    return pricesForPort.some((category) => category.bestPriceCategory === true);
};

export const FuelScheduleSection = ({ vessel }) => {
    const [slidesPerView, setSlidesPerView] = useState(0);
    const [activeSlide, setActiveSlide] = useState(0);
    const [pricesMap, setPricesMap] = useState({});

    const plattsCredentialsQuery = usePlattsCredentials(vessel.customerId);
    const fuelSchedulesWithPricesQuery = useFuelSchedulesWithPrices(vessel);

    useEffect(() => {
        if (fuelSchedulesWithPricesQuery.data?.prices) {
            setPricesMap(createFuelPricesMap(fuelSchedulesWithPricesQuery.data.prices));
        }
    }, [fuelSchedulesWithPricesQuery.data]);

    const scheduleIdToReachInformationMap = createReachInformationMap(
        fuelSchedulesWithPricesQuery.data?.reachInformation
    );

    const cheapestDefaultScope = useCheapestDefaultScope({
        hasCheapestDefaultFn: stopHasCheapestDefault,
        activeSlide,
        slidesPerView,
        pricesMap,
        schedules: fuelSchedulesWithPricesQuery.data?.schedules,
    });

    if (fuelSchedulesWithPricesQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (fuelSchedulesWithPricesQuery.isError) {
        return <AdvancedError />;
    }

    if (!fuelSchedulesWithPricesQuery.data.schedules?.length) {
        return <TableEmptyState text={translate('vessel.scheduleEmpty')} />;
    }

    const negativeStopIndices = [];
    const filledScheduleList = fuelSchedulesWithPricesQuery.data.schedules.map((stop, index) => {
        const reachInformation = scheduleIdToReachInformationMap[stop.id];

        if (reachInformation) {
            Object.values(reachInformation)
                .filter((it) => [CategoryState.OUT_OF_REACH, CategoryState.SAFETY_RESERVE].includes(it.categoryState))
                .forEach((it) => {
                    negativeStopIndices.push({
                        categoryState: it.categoryState,
                        index,
                    });
                });
        }

        return (
            <ScheduleStopContainer key={stop.id}>
                <ScheduleStop stop={stop} vesselId={vessel.id} index={index} />
                {pricesMap[stop.port.id] ? (
                    <FuelSchedulePriceCompare
                        prices={pricesMap[stop.port.id]}
                        reachInformation={scheduleIdToReachInformationMap[stop.id]}
                        hasPlattsCredentials={!!plattsCredentialsQuery.data}
                        customerId={vessel.customerId}
                    />
                ) : null}
            </ScheduleStopContainer>
        );
    });

    return (
        <ScheduleWrapper>
            <Section
                title={translate('vessel.schedule')}
                actions={
                    <Text variant="extraSmall" color="foreground.subtle" display="block">
                        {translate('platts.providedByPlatts')}
                    </Text>
                }
                position="relative"
                overflow="hidden"
                paddingX={0}
            >
                <ScheduleSlider
                    activeSlide={activeSlide}
                    onSlideChange={setActiveSlide}
                    scheduleList={filledScheduleList}
                    negativeStopIndices={negativeStopIndices}
                    cheapestDefaultScope={cheapestDefaultScope}
                    onSlidesPerViewChange={(slides) => {
                        setSlidesPerView(slides);
                        setActiveSlide(0);
                    }}
                />
            </Section>
        </ScheduleWrapper>
    );
};
