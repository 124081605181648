import { Button } from '../../cdl/Button/Button';
import { APPROVAL_STATE } from '../../common/approvalState.constant';
import { translate } from '../../common/helpers/translate.helper';

import { IconArrowBack } from '../../common/icons/cdl/ArrowBack';
import { IconArrowBackUp } from '../../common/icons/cdl/ArrowBackUp';

export const OrderedRequestButton = ({ offerApprovalSettings, approvalRequests, onRequestApprovalButtonClicked }) => {
    if (!approvalRequests.length && offerApprovalSettings.requiresApproval) {
        return (
            <Button onClick={onRequestApprovalButtonClicked}>
                {translate('approvalRequest.buttons.requestApproval')}
            </Button>
        );
    }

    if (approvalRequests.length) {
        const latestApprovalRequest = approvalRequests[0];

        switch (latestApprovalRequest.state) {
            case APPROVAL_STATE.PENDING: {
                if (!offerApprovalSettings.requiresApproval) {
                    return null;
                }

                return (
                    <Button
                        onClick={onRequestApprovalButtonClicked}
                        leadingVisual={<IconArrowBackUp width={16} height={16} />}
                    >
                        {translate('approvalRequest.buttons.sendRequestAgain')}
                    </Button>
                );
            }

            case APPROVAL_STATE.APPROVED:
                return null;
            default:
                return (
                    <Button
                        onClick={onRequestApprovalButtonClicked}
                        leadingVisual={<IconArrowBack width={16} height={16} />}
                    >
                        {translate('approvalRequest.buttons.reRequestApproval')}
                    </Button>
                );
        }
    }

    return null;
};
