import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import styled from 'styled-components';

export const RadioCardGroupRoot = styled(RadixRadioGroup.Root)`
    overflow: scroll;
    scrollbar-width: none;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
`;
