import { WithdrawnApprovalRequestMessage } from '../../../../types/ApprovalRequestMessage';
import { Text } from '../../../cdl/Text/Text';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { formatMoney } from '../../../common/helpers/formatMoney.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';
import { Paragraph } from '../../../common/ui/Paragraph';
import { ApprovalResponse } from '../ApprovalResponse';
import { MetaData } from '../MetaData';

import { ApprovalRequestContentWrapper } from './ApprovalRequestContentWrapper';

interface WithdrawnApprovalRequestContentProps {
    approvalRequest: WithdrawnApprovalRequestMessage;
}

export const WithdrawnApprovalRequestContent = (props: WithdrawnApprovalRequestContentProps) => {
    const requestText = props.approvalRequest.requestMessage
        ? translate('approvalRequest.dropdown.createdHeadlineComment', {
              requestMessage: props.approvalRequest.requestMessage,
          })
        : translate('approvalRequest.dropdown.createdHeadlineNoComment');

    return (
        <ApprovalRequestContentWrapper>
            {props.approvalRequest.total ? (
                <Text size={14} weight="bold">
                    {translate('approvalRequest.dropdown.withdrawnTotal', {
                        value: formatMoney({
                            value: props.approvalRequest.total.value,
                            currency: props.approvalRequest.total.currency,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 3,
                        }),
                    })}
                </Text>
            ) : null}
            <Paragraph>{requestText}</Paragraph>
            <MetaData>
                {translate('approvalRequest.dropdown.changeInformation', {
                    date: formatDateTime({
                        date: props.approvalRequest.dateCreated,
                    }),
                    author: `${props.approvalRequest.createdByUser.firstname} ${props.approvalRequest.createdByUser.lastname}`,
                })}
            </MetaData>
            <ApprovalResponse
                response={
                    props.approvalRequest.responseMessage
                        ? translate('approvalRequest.dropdown.approved.responseComment', {
                              responseMessage: props.approvalRequest.responseMessage,
                          })
                        : translate('approvalRequest.dropdown.approved.responseNoComment')
                }
                metaData={translate('approvalRequest.dropdown.changeInformation', {
                    date: formatDateTime({
                        date: props.approvalRequest.dateApproved,
                    }),
                    author: props.approvalRequest.controllerEmailAddress,
                })}
            />
            <Box marginLeft={7}>
                <ApprovalResponse
                    response={translate('approvalRequest.dropdown.withdrawn.headline')}
                    metaData={translate('approvalRequest.dropdown.withdrawn.subHeadline', {
                        date: formatDateTime({
                            date: props.approvalRequest.dateWithdrawn,
                        }),
                    })}
                />
            </Box>
        </ApprovalRequestContentWrapper>
    );
};
