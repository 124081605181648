import { AccessLevel } from '../../../../../types/AccessLevel';
import { UserType } from '../../../../../types/UserType';
import { Button } from '../../../../cdl/Button/Button';
import { useDrawer } from '../../../../cdl/Drawer/useDrawer.hook';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconPlus } from '../../../../common/icons/cdl/Plus';
import { useApiKeyCreate } from '../hooks/useApiKeyCreate';

import { ApiKeyFormState } from './ApiKeyBaseForm';
import { ApiKeyForm } from './ApiKeyForm';

interface CreateApiKeyButtonProps {
    groupId: string;
    userType: UserType.CUSTOMER | UserType.SUPPLIER;
}

export const CreateApiKeyButton = ({ groupId, userType }: CreateApiKeyButtonProps) => {
    const { push, pop } = useDrawer();
    const apiKeyCreateMutation = useApiKeyCreate();

    const onSubmit = async (values: ApiKeyFormState) => {
        const companyAccesses = values.companyIds.map((companyId) => {
            return {
                companyId: companyId,
                accessLevel: values.companyAccessLevel!,
            };
        });

        await apiKeyCreateMutation.mutateAsync({
            name: values.name,
            groupId: groupId,
            userType: userType,
            accessLevel: AccessLevel.WRITE,
            companyAccesses: companyAccesses,
        });

        pop();
    };

    const onCreateButtonClick = () => {
        push({
            content: (
                <ApiKeyForm
                    onSubmit={onSubmit}
                    groupId={groupId}
                    title={translate('apikey.createtitle')}
                    userType={userType}
                />
            ),
            width: '15vw',
        });
    };

    return (
        <Button leadingVisual={<IconPlus height={16} width={16} />} onClick={onCreateButtonClick}>
            {translate('apikey.add')}
        </Button>
    );
};
