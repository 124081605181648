import { linkOptions, useNavigate, useSearch } from '@tanstack/react-router';

import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { Tabs } from '../../../cdl/Tabs/Tabs';
import { AdvancedError } from '../../../common/Error/AdvancedError';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatLubesPrice } from '../../../common/helpers/formatLubesPrice.helper';
import { formatMoney } from '../../../common/helpers/formatMoney.helper';
import { formatNumber } from '../../../common/helpers/formatNumber.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate as t } from '../../../common/helpers/translate.helper';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { IconListDetails } from '../../../common/icons/cdl/ListDetails';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { OfferStatusTag } from '../../../common/OfferStatusTag/OfferStatusTag';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { Box } from '../../../common/ui/Box';
import { UnreadIndicator } from '../../../order/common/UnreadIndicator';
import { Port } from '../../../port/Port';
import { OfferModel } from '../../model/OfferModel';
import { useOfferPagination } from '../../useOfferPagination';
import { OfferActions } from '../actions/OfferActions';

import { lubesTabToOfferStateMap } from './lubesTabToOfferStateMap';
import { useLubesOfferPaginationTotals } from './useLubesOfferPaginationTotals';

export const LubesOfferOverviewTable = () => {
    const { context } = useProductContext();
    const role = useRole();

    const handleSupplierIds = (supplierIds: string[]): string[] | undefined => {
        if (supplierIds.length) {
            return supplierIds;
        }

        if (role.isAdmin()) {
            return undefined;
        }

        return role.getCompaniesWithType(context).map((it) => it.id);
    };

    const search = useSearch({ from: '/_app/_lubes/offers' });
    const navigate = useNavigate({ from: '/offers' });

    const offerPaginationTotals = useLubesOfferPaginationTotals({
        searchQuery: search.searchQuery,
        supplierIds: handleSupplierIds(search.supplierIds),
        customerIds: search.customerIds,
    });
    const offersTotals = offerPaginationTotals.data ?? {};

    const offerPaginationQuery = useOfferPagination({
        page: search.page,
        searchQuery: search.searchQuery,
        supplierIds: handleSupplierIds(search.supplierIds),
        customerIds: search.customerIds,
        types: [ProductContext.LUBES],
        states: lubesTabToOfferStateMap[search.tab],
        sortField: search.sortValue?.sortField,
        sortDirection: search.sortValue?.sortDirection,
    });

    const onTabSelect = (tab: string) => {
        navigate({
            search: (previousSearch) => ({
                ...previousSearch,
                tab,
                page: 0,
            }),
        });
    };

    if (offerPaginationQuery.isPending) {
        return (
            <div>
                <Tabs selectedContext={search.tab} onSelect={onTabSelect} totals={offersTotals} />
                <LoadingIndicator />
            </div>
        );
    }

    if (offerPaginationQuery.isError) {
        return (
            <div>
                <Tabs selectedContext={search.tab} onSelect={onTabSelect} totals={offersTotals} />

                <AdvancedError />
            </div>
        );
    }

    if (!offerPaginationQuery.data?.content.length) {
        return (
            <div>
                <Tabs selectedContext={search.tab} onSelect={onTabSelect} totals={offersTotals} />
                <TableEmptyState Icon={IconListDetails} text={t('order.emptylist')} />
            </div>
        );
    }

    return (
        <div>
            <Tabs selectedContext={search.tab} onSelect={onTabSelect} totals={offersTotals} />

            <TableBuilder
                data={offerPaginationQuery.data.content}
                rowLink={(offer) => linkOptions({ to: '/offer/$id', params: { id: offer.id } })}
                isLoading={offerPaginationQuery.isPlaceholderData && offerPaginationQuery.isFetching}
                grow={false}
            >
                {role.isSupplier() ? (
                    <TableBuilderColumn<OfferModel> header="" width="1%" hideOnLoading>
                        {(offer) => <Box width="8px">{!offer.supplierRead ? <UnreadIndicator /> : null}</Box>}
                    </TableBuilderColumn>
                ) : null}

                <TableBuilderColumn<OfferModel> header={t('order.vendorreferenceshort')} width="10%" truncate>
                    {(offer) => offer.vendorReference ?? '-'}
                </TableBuilderColumn>

                {role.isAdmin() || !role.isOneCompanyUser('LUBES') ? (
                    <TableBuilderColumn<OfferModel>
                        header={role.isSupplier() ? t('offer.company') : t('offer.supplier')}
                        width="10%"
                        truncate
                    >
                        {(offer) => formatCompanyName({ company: offer.supplier })}
                    </TableBuilderColumn>
                ) : null}

                <TableBuilderColumn<OfferModel> header={t('offer.customer')} width="10%" truncate>
                    {(offer) => formatCompanyName({ company: offer.customer })}
                </TableBuilderColumn>

                <TableBuilderColumn<OfferModel> header={t('order.vessel')} width="15%" truncate>
                    {(offer) => formatVessel({ vessel: offer.vessel, short: true })}
                </TableBuilderColumn>

                <TableBuilderColumn<OfferModel> header={t('order.port')} width="10%">
                    {(offer) => {
                        if (!offer.port) {
                            return null;
                        }

                        return <Port port={offer.port} vesselId={offer.vesselId} locode={false} showTooltip />;
                    }}
                </TableBuilderColumn>

                <TableBuilderColumn<OfferModel> header={t('order.dateDeliveryShort')} width="10%">
                    {(offer) => {
                        if (!offer.dateDelivery) {
                            return null;
                        }

                        return formatDate({
                            date: offer.dateDelivery,
                            timeZone: 'UTC',
                        });
                    }}
                </TableBuilderColumn>

                {search.tab === 'enquired' ? (
                    <TableBuilderColumn<OfferModel> header={t('order.volume')} width="5%">
                        {(offer) => `${formatNumber({ number: offer.volume })}L`}
                    </TableBuilderColumn>
                ) : null}

                {role.isAdmin() ? (
                    <TableBuilderColumn<OfferModel> header={t('order.ppl')} width="10%">
                        {(offer) => {
                            if (offer.ppl?.value && offer.ppl.value > 0) {
                                return formatLubesPrice(offer.ppl);
                            }

                            return null;
                        }}
                    </TableBuilderColumn>
                ) : null}

                {role.isAdmin() ? (
                    <TableBuilderColumn<OfferModel> header={t('order.total')} width="10%">
                        {(offer) => {
                            if (offer.total.value > 0) {
                                return formatMoney({
                                    value: offer.total.value,
                                    currency: offer.total.currency,
                                    minimumFractionDigits: 2,
                                });
                            }

                            return null;
                        }}
                    </TableBuilderColumn>
                ) : null}

                {!['acknowledged', 'confirmed', 'delivered'].includes(search.tab) ? (
                    <TableBuilderColumn<OfferModel> header={t('order.status')} width="5%">
                        {(offer) => <OfferStatusTag state={offer.state} variant="supplier" />}
                    </TableBuilderColumn>
                ) : null}

                {role.hasWriteRights() && role.isSupplier() ? (
                    <TableBuilderColumn<OfferModel> header="" width="1%" hideOnLoading interactive>
                        {(offer) => <OfferActions offer={offer} />}
                    </TableBuilderColumn>
                ) : null}
            </TableBuilder>

            <CenteredPagination
                pageTotal={offerPaginationQuery.pageTotal}
                currentPage={search.page}
                onPageChange={(page) => {
                    navigate({
                        search: (previousSearch) => ({
                            ...previousSearch,
                            page,
                        }),
                    });
                }}
            />
        </div>
    );
};
