import styled from 'styled-components';

import { get } from '../ui/get';

export const TopBarButton = styled.button`
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    color: ${get('colors.foreground.subtle')};

    &:hover {
        color: ${get('colors.accent.emphasis')};
        background-color: ${get('colors.background.subtle')};
    }
`;
