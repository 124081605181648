import { useMutation } from '@tanstack/react-query';
import FileDownload from 'js-file-download';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { downloadVesselRequisitionForCustomer } from '../../../common/api/clients/vesselRequisition.api';
import { extractFileName } from '../../../common/helpers/extractFileName.helper';
import { translate } from '../../../common/helpers/translate.helper';

export const useVesselRequisitionCustomerDownload = () => {
    const { addLoadingToast } = useToasts();
    const { mutateAsync: executeVesselRequisitionDownload } = useMutation({
        mutationFn: downloadVesselRequisitionForCustomer,
    });

    return async (customerId: string) => {
        return addLoadingToast(
            () =>
                executeVesselRequisitionDownload(customerId, {
                    onSuccess: (response) =>
                        FileDownload(
                            response.data,
                            extractFileName(response.headers['content-disposition'] || '', 'VesselRequisitions.zip')
                        ),
                }),
            translate('customers.requisitionForm.downloading'),
            translate('customers.requisitionForm.downloadSuccess'),
            translate('customers.requisitionForm.downloadError')
        );
    };
};
