import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

import { getRole } from '../common/hooks/useRole';

export const Route = createFileRoute('/_app/fuel')({
    beforeLoad: () => {
        const role = getRole();

        if (!role.mayUseContext('FUEL')) {
            throw redirect({
                to: '/dashboard',
            });
        }

        role.updateContext('FUEL');
    },
    component: () => <Outlet />,
});
