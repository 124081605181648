import { IconCircle1 } from '../../icons/cdl/Circle1';
import { IconCircle2 } from '../../icons/cdl/Circle2';
import { IconCircleCheck } from '../../icons/cdl/CircleCheck';
import { theme } from '../../ui/theme';

export const StepMarker = ({ step, active }) => {
    const color = active ? theme.colors.accent.foreground : theme.colors.foreground.disabled;

    switch (step) {
        case 1:
            return <IconCircle1 color={color} />;
        case 2:
            return <IconCircle2 color={color} />;
        case 3:
            return <IconCircleCheck color={color} />;
        default:
            return null;
    }
};
