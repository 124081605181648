import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { createQuotationDocument, CreateQuotationDocumentRequestMessage } from '../common/api/clients/bass-net-api';

export const useBassNetQuotationDocument = () => {
    return useMutation<void, AxiosError<{ message: string }>, CreateQuotationDocumentRequestMessage>({
        mutationFn: createQuotationDocument,
    });
};
