import { useQuery } from '@tanstack/react-query';

import { getCustomerSettings } from '../../../../common/api/clients/companySettings.api';
import { queries } from '../../../../common/api/queryKeys/queries';
import { useRole } from '../../../../common/hooks/useRole';

export const useIsApprovalAllowed = () => {
    const role = useRole();
    const companies = role.companies || [];
    const companyIds = companies.map((company) => company.id);

    const fetchCustomerSettings = () => {
        return Promise.all(companyIds.map((companyId) => getCustomerSettings(companyId)));
    };

    const query = useQuery({
        queryKey: queries.customers.settings(companyIds).queryKey,
        queryFn: fetchCustomerSettings,
        select: (data) => data.some((settings) => settings.approvalSettings.activated),
        initialData: () => [],
        enabled: !!companies.length,
    });

    if (role.isAdmin()) {
        return true;
    }

    if (!companies.length) {
        return false;
    }
    return query.data ?? false;
};
